<script lang="ts" setup>
import { computed } from 'vue';
import { VHeadingLevel, VHeadingAppearance } from './types';

const props = defineProps<{
  level: VHeadingLevel;
  appearance?: VHeadingAppearance;
}>();

const defaultAppearanceByLevel: Record<VHeadingLevel, VHeadingAppearance> = {
  1: 'l',
  2: 'm',
  3: 'm',
  4: 'm',
  5: 'xs',
  6: 'xs'
};

const fontSizeByAppearance: Record<VHeadingAppearance, string> = {
  l: 'tw-text-3xl',
  m: 'tw-text-xl',
  xs: 'tw-text-base'
};

const level = computed(() => props.level);
const appearance = computed(() => props.appearance ?? defaultAppearanceByLevel[props.level]);
</script>
<template>
  <Component :is="`h${level}`" :class="`tw-font-bold tw-text-primary-900 ${fontSizeByAppearance[appearance]}`">
    <slot></slot>
  </Component>
</template>

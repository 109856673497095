<script lang="ts" setup>
import { computed } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';

const props = defineProps<{
  link: string | RouteLocationRaw;
  target?: string;
}>();

const isExternal = computed(() => typeof props.link !== 'string');
</script>

<template>
  <RouterLink v-slot="{ href, isExactActive, navigate }" custom :to="link">
    <li
      class="tw-group/breadcrumb-item tw-hidden tw-min-w-0 tw-max-w-full last:tw-flex after:not-last:tw-flex after:not-last:tw-items-center after:not-last:tw-text-grey-200 after:not-last:tw-content-['/'] @sm/breadcrumb:[&:nth-last-child(-n+2)]:tw-flex @lg/breadcrumb:[&:nth-last-child(-n+3)]:tw-flex"
      :class="{ 'tw-pointer-events-none tw-cursor-default': isExactActive }">
      <a
        :aria-current="isExactActive ? 'page' : undefined"
        class="tw-flex tw-min-w-0 tw-items-center tw-text-primary-900 hover:tw-no-underline group-last/breadcrumb-item:tw-font-bold group-last/breadcrumb-item:tw-text-primary @sm/breadcrumb:group-[:last-child:not(:first-child)]/breadcrumb-item:tw-pl-2 @sm/breadcrumb:group-[:nth-last-child(2)]/breadcrumb-item:tw-pr-2 @lg/breadcrumb:group-[:nth-last-child(2):not(:first-child)]/breadcrumb-item:tw-pl-2 @lg/breadcrumb:group-[:nth-last-child(3)]/breadcrumb-item:tw-pr-2"
        :href="isExternal ? href : (link as string)"
        :target="props.target"
        @[isExternal&&`click`]="navigate">
        <span class="tw-truncate">
          <slot></slot>
        </span>
      </a>
    </li>
  </RouterLink>
</template>

export const datatable = {
  root: ({ props }: Record<string, any>) => ({
    class: [
      'tw-relative tw-group/data-table',
      // Flex & Alignment
      { 'tw-flex tw-flex-col tw-gap-6': props.scrollable && props.scrollHeight === 'flex' },
      // Size
      { 'tw-h-full': props.scrollable && props.scrollHeight === 'flex' }
    ]
  }),
  mask: {
    class: [
      'tw-absolute',
      'tw-top-0 tw-left-0',
      'tw-z-20',
      'tw-flex tw-items-center tw-justify-center',
      'tw-w-full tw-h-full',
      'tw-bg-surface-100/40',
      'tw-transition tw-duration-200'
    ]
  },
  loadingIcon: {
    class: 'tw-w-8 tw-h-8 tw-animate-spin'
  },
  header: ({ props }: Record<string, any>) => ({
    class: [
      'group-[:not(:first-child)]/data-table:tw-pt-6 group-[:not(:first-child)]/data-table:tw-border-t group-[:not(:first-child)]/data-table:tw-border-t-grey-200',
      // Shape
      props.showGridlines ? 'tw-border-x tw-border-t tw-border-b-0' : 'tw-border-x-0',
      // Color
      'tw-bg-surface-0',
      'tw-border-surface-200',
      'tw-text-surface-700'
    ]
  }),
  tableContainer: ({ props }: Record<string, any>) => ({
    class: [
      'tw-border tw-border-grey-200 tw-rounded tw-scrollbar-thin tw-bg-white',
      {
        'tw-relative': props.scrollable,
        'tw-flex tw-flex-col tw-grow': props.scrollable && props.scrollHeight === 'flex'
      },
      // Size
      { 'tw-h-full': props.scrollable && props.scrollHeight === 'flex' }
    ]
  }),
  table: {
    class: 'tw-w-full tw-border-spacing-0 tw-border-separate'
  },
  footer: {
    class: [
      'tw-font-bold',
      'tw-border-t-0 tw-border-b tw-border-x-0',
      'tw-p-4',
      'tw-bg-surface-0',
      'tw-border-surface-200',
      'tw-text-surface-700'
    ]
  },
  thead: ({ context }: Record<string, any>) => ({
    class: [
      'tw-bg-grey-50',
      {
        'tw-top-0 tw-z-40 tw-sticky': context.scrollable
      }
    ]
  }),
  tbody: ({ instance, context }: Record<string, any>) => ({
    class: [
      'tw-bg-white',
      {
        'tw-sticky tw-z-20': instance.frozenRow && context.scrollable
      }
    ]
  }),
  tfoot: ({ context }: Record<string, any>) => ({
    class: [
      {
        'tw-bg-surface-0 tw-bottom-0 tw-z-0': context.scrollable
      }
    ]
  }),
  column: {
    headercell: ({ context, props }: Record<string, any>) => ({
      class: [
        'tw-font-semibold',
        'tw-leading-[normal]',
        'tw-outline-none',
        // Position
        { 'tw-sticky tw-z-20 tw-border-b': props.frozen || props.frozen === '' },
        { 'tw-relative': context.resizable },
        // Alignment
        'tw-text-left',
        // Shape
        { 'tw-first:border-l tw-border-y tw-border-r': context == null ? undefined : context.showGridlines },
        'tw-border-0 tw-border-b tw-border-solid',
        // Spacing
        (context == null ? undefined : context.size) === 'small'
          ? 'tw-py-[0.375rem] tw-px-2'
          : (context == null ? undefined : context.size) === 'large'
            ? 'tw-py-[0.9375rem] tw-px-5'
            : 'tw-py-[.8125rem] tw-px-3 first:tw-pl-6 [&:nth-last-child(2)]:tw-pr-6',
        // Color
        'tw-border-grey-200',
        // Transition
        { 'tw-transition tw-duration-200': props.sortable === '' || props.sortable },
        // Misc
        { 'tw-cursor-pointer': props.sortable === '' || props.sortable },
        {
          'tw-overflow-hidden tw-space-nowrap tw-border-y tw-bg-clip-padding': context.resizable
          // Resizable
        }
      ]
    }),
    columnHeaderContent: {
      class: 'tw-flex tw-items-center'
    },
    columnTitle: {
      class: 'tw-text-sm tw-font-bold tw-text-grey-500'
    },
    sort: {
      class: 'tw-text-grey-600 tw-leading-none'
    },
    bodyCell: ({ props, context, state, parent }: Record<string, any>) => ({
      class: [
        'tw-text-primary-900',
        // Font
        'tw-leading-[normal]',
        // Position
        'tw-relative',
        { 'tw-sticky tw-box-border tw-border-b': parent.instance.frozenRow },
        { 'tw-sticky tw-box-border tw-border-b tw-z-20': props.frozen || props.frozen === '' },
        // Alignment
        'tw-text-left',
        // Shape
        'group-[:not(:first-child)]/data-table-row:after:tw-absolute group-only/data-table-row:after:tw-absolute group-[:not(:first-child)]/data-table-row:after:tw-right-0 group-only/data-table-row:after:tw-right-0 group-[:not(:first-child)]/data-table-row:after:tw-top-0 group-only/data-table-row:after:tw-top-0 group-[:not(:first-child)]/data-table-row:after:tw-left-0 group-only/data-table-row:after:tw-left-0 group-[:not(:first-child)]/data-table-row:first:after:tw-left-6 group-only/data-table-row:first:after:tw-left-6 group-[:not(:first-child)]/data-table-row:after:tw-border-b group-only/data-table-row:after:tw-border-b group-[:not(:first-child)]/data-table-row:after:tw-border-b-grey-200 group-only/data-table-row:after:tw-border-b-grey-200',
        { 'tw-first:border-l tw-border-r tw-border-b': context == null ? undefined : context.showGridlines },
        { 'tw-bg-surface-0': parent.instance.frozenRow || props.frozen || props.frozen === '' },
        // Spacing
        { 'tw-py-[0.375rem] tw-px-2': (context == null ? undefined : context.size) === 'small' && !state.d_editing },
        { 'tw-py-[0.9375rem] tw-px-5': (context == null ? undefined : context.size) === 'large' && !state.d_editing },
        {
          'tw-h-14 tw-py-2.5 tw-px-3 first:tw-pl-6 [&:nth-last-child(2)]:tw-pr-6':
            (context == null ? undefined : context.size) !== 'large' &&
            (context == null ? undefined : context.size) !== 'small' &&
            !state.d_editing
        },
        { 'tw-py-[0.6rem] tw-px-2': state.d_editing },
        // Links
        '[&>a]:tw-text-inherit [&>a]:tw-underline [&>a]:hover:tw-text-primary [&>a]:after:tw-absolute [&>a]:after:tw-inset-0'
      ]
    }),
    footerCell: ({ context }: Record<string, any>) => ({
      class: [
        // Font
        'tw-font-bold',
        // Alignment
        'tw-text-left',
        // Shape
        'tw-border-0 tw-border-b tw-border-solid',
        { 'tw-border-x tw-border-y': context == null ? undefined : context.showGridlines },
        // Spacing
        (context == null ? undefined : context.size) === 'small'
          ? 'tw-p-2'
          : (context == null ? undefined : context.size) === 'large'
            ? 'tw-p-5'
            : 'tw-p-4',
        // Color
        'tw-border-surface-200',
        'tw-text-surface-700',
        'tw-bg-surface-0'
      ]
    }),
    sortIcon: ({ context }: Record<string, any>) => ({
      class: ['tw-ml-1', context.sorted ? 'tw-text-primary-highlight-inverse' : 'tw-text-surface-700']
    }),
    pcSortBadge: {
      class: [
        'tw-flex tw-items-center tw-justify-center tw-align-middle',
        'tw-rounded-full',
        'tw-w-[1.143rem] tw-leading-[1.143rem]',
        'tw-ml-2',
        'tw-text-primary-highlight-inverse tw-bg-primary-highlight'
      ]
    },
    rowCheckbox: {
      root: {
        class: [
          'tw-relative',
          'tw-inline-flex',
          'tw-align-bottom',
          'tw-w-5',
          'tw-h-5',
          'tw-cursor-pointer',
          'tw-select-none'
        ]
      },
      box: ({ props, context }: Record<string, any>) => ({
        class: [
          // Alignment
          'tw-flex',
          'tw-items-center',
          'tw-justify-center',
          // Size
          'tw-w-5',
          'tw-h-5',
          // Shape
          'tw-rounded',
          'tw-border',
          // Colors
          {
            'tw-border-surface-300': !context.checked && !props.invalid,
            'tw-bg-surface-0': !context.checked && !props.invalid && !props.disabled,
            'tw-border-primary tw-bg-primary': context.checked
          },
          // Invalid State
          'invalid:focus:tw-ring-red-200',
          'invalid:hover:tw-border-red-500',
          { 'tw-border-red-500': props.invalid },
          // States
          {
            'peer-hover:tw-border-surface-400': !props.disabled && !context.checked && !props.invalid,
            'peer-hover:tw-bg-primary-hover peer-hover:tw-border-primary-hover': !props.disabled && context.checked,
            'peer-focus-visible:tw-z-10 peer-focus-visible:tw-outline-none peer-focus-visible:tw-outline-offset-0 peer-focus-visible:tw-ring-1 peer-focus-visible:tw-ring-primary-500':
              !props.disabled,
            'tw-bg-surface-200 select-none pointer-events-none cursor-default': props.disabled
          },
          // Transitions
          'tw-transition-colors',
          'tw-duration-200'
        ]
      }),
      input: {
        class: [
          'peer',
          'tw-w-full ',
          'tw-h-full',
          'tw-absolute',
          'tw-top-0 tw-left-0',
          'tw-z-10',
          'tw-p-0',
          'tw-m-0',
          'tw-opacity-0',
          'tw-rounded',
          'tw-outline-none',
          'tw-border tw-border-surface-300',
          'tw-appearance-none',
          'tw-cursor-pointer'
        ]
      },
      icon: {
        class: ['tw-w-[0.875rem]', 'tw-h-[0.875rem]', 'tw-text-white', 'tw-transition-all', 'tw-duration-200']
      }
    },
    transition: {
      class: 'tw-p-3 tw-flex tw-flex-col tw-gap-2',
      enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
      enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
      leaveToClass: 'tw-opacity-0'
    }
  },
  bodyRow: ({ context, parent, props }: Record<string, any>) => ({
    class: [
      'tw-group/data-table-row',
      {
        'hover:tw-bg-grey-50 tw-cursor-pointer': parent.attrs.showRowArrow
      },
      // Color
      { 'tw-bg-primary-highlight tw-text-primary-highlight-inverse': context.selected },
      { 'tw-bg-surface-0 tw-text-surface-600': !context.selected },
      { 'tw-font-bold tw-bg-surface-0 tw-z-20': props.frozenRow },
      {
        'odd:tw-bg-surface-0 odd:tw-text-surface-600 even:tw-bg-surface-50 even:tw-text-surface-600':
          context.stripedRows
      },
      // State
      {
        'tw-focus:outline-none tw-focus:outline-offset-0 tw-focus:ring-1 tw-focus:ring-primary-500 tw-ring-inset':
          context.selectable
      },
      {
        'tw-hover:bg-surface-300/20 tw-hover:text-surface-600': props.selectionMode && !context.selected
      },
      // Transition
      { 'tw-transition tw-duration-200': (props.selectionMode && !context.selected) || props.rowHover },
      // Misc
      { 'tw-cursor-pointer': props.selectionMode }
    ]
  }),
  emptyMessageCell: {
    class: 'tw-p-0'
  }
};

import { unref } from 'vue';
import { logger } from '@tools-lib';
import { useNotification } from '@shared/plugin';
import { ProblemDetail } from './problemDetails';
import {
  BaseError,
  Conflict,
  ForbiddenError,
  isSbAxiosError,
  NotFoundError,
  SbError,
  ValidationError,
  TimeoutError
} from './sbError';
import { ErrorHandlerOptions, OnError } from './types';

export const defaultMessage = 'Er is een onverwachte fout opgetreden.';
export const defaultSolution =
  '<a href="/net/home/about" target="_blank">Neem contact op</a> met een van onze medewerkers of probeer het later opnieuw.';
const notification = useNotification();

export const convertExternalValidations = (detail: ProblemDetail, keyToLowercase = true): Record<string, string> =>
  Object.entries(detail).reduce(
    (record, [key, value]) => {
      if (Array.isArray(value)) {
        if (keyToLowercase) {
          record[key.toLowerCase()] = value.join(' ');
        } else {
          record[key] = value.join(' ');
        }
      }

      return record;
    },
    {} as Record<string, string>
  );

export const prefixProblemDetailKeys = (detail: ProblemDetail, prefix: string): ProblemDetail =>
  Object.entries(detail).reduce((record, [key, value]) => {
    record[`${prefix}.${key.toLowerCase()}`] = value;
    return record;
  }, {} as ProblemDetail);

const vermelding = (correlationId: string) =>
  `<br /><br />Vermeld altijd dit kenmerk: <p class='tw-font-mono'>${correlationId}</p>`;

export function showNotification(error: SbError, options: { customMessage?: string; customSolution?: string } = {}) {
  const message = options.customMessage || (error.message ?? defaultMessage);

  const referentie = error.correlationId ? vermelding(error.correlationId) : '';
  const solution = options.customSolution || defaultSolution;
  notification.error(`${message}<br /> <br />${solution} ${referentie}`);
}

function onHandleError<T extends SbError>(error: T, onErrorHandler: OnError<T>) {
  const unwrappedOnErrorHandler = unref(onErrorHandler);
  if (typeof unwrappedOnErrorHandler === 'string') {
    showNotification(error, { customMessage: unwrappedOnErrorHandler });
  } else {
    unwrappedOnErrorHandler(error);
  }
}

function handleSbError(error: SbError, options: ErrorHandlerOptions) {
  if (error instanceof ValidationError && options.onValidationError) {
    onHandleError(error, options.onValidationError);
  } else if (error instanceof ForbiddenError && options.onForbiddenError) {
    onHandleError(error, options.onForbiddenError);
  } else if (error instanceof NotFoundError && options.onNotFoundError) {
    onHandleError(error, options.onNotFoundError);
  } else if (error instanceof Conflict && options.onConflictError) {
    onHandleError(error, options.onConflictError);
  } else if (error instanceof TimeoutError && options.onTimeoutError) {
    onHandleError(error, options.onTimeoutError);
  } else {
    onHandleError(error, options.onError || showNotification);
  }
}

export function createErrorMessage(error: Error | null | SbError, options?: ErrorHandlerOptions) {
  if (isSbAxiosError(error)) {
    const { correlationId } = error.sbError;
    if (error.sbError instanceof NotFoundError && options?.onNotFoundError) {
      return options.onNotFoundError;
    }
    if (error.sbError instanceof ValidationError && options?.onValidationError) {
      return options.onValidationError;
    }
    if (error.sbError instanceof ForbiddenError && options?.onForbiddenError) {
      return options?.onForbiddenError;
    }
    if (error.sbError instanceof Conflict && options?.onConflictError) {
      return options?.onConflictError;
    }
    return `${options?.defaultMessage ?? defaultMessage} ${options?.defaultSolution ?? defaultSolution}${
      correlationId ? vermelding(correlationId) : ''
    }`;
  }

  return `${options?.defaultMessage ?? defaultMessage} ${options?.defaultSolution ?? defaultSolution}`;
}

export function handleError(error: Error | null | SbError, options: ErrorHandlerOptions = {}) {
  logger.debug('handleError', error);
  if (!error) return;

  if (isSbAxiosError(error)) {
    handleSbError(error.sbError, options);
    return;
  }

  if (error instanceof BaseError) {
    handleSbError(error, options);
    return;
  }

  notification.error(`${defaultMessage}<br />${defaultSolution}`);
}

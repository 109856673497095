export const popover = {
  root: {
    class: ['tw-translate-y-px', 'tw-rounded']
  },
  content: {
    class: ['tw-items-center tw-flex', 'tw-rounded']
  },
  transition: {
    enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
    enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
    leaveToClass: 'tw-opacity-0'
  }
};

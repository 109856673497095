<script lang="ts" setup>
import loader from '@assets/images/loader.gif';
import { useClaims } from '@auth';
import { env } from '@configuration-lib';
import { AppLayout, useBreadcrumbStore } from '@menu-lib';
import { VInfoBox } from '@shared/component';
import { FontAwesomeIcon } from '@shared/plugin';
import { htmlDecode, logger } from '@tools-lib';
import {
  useClipboard,
  useEventListener,
  useLocalStorage,
  useMagicKeys,
  useSessionStorage,
  whenever
} from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

// voor het inladen van de app checken we of de gebruiker ingelogd is
// zodat deze direct ge-redirect kan worden naar het loginscherm
const claims = useClaims();

const canRender = ref<boolean>(false);
const error = ref<string>();
const iFrameSrc = ref<string>();
const menuIframe = ref<HTMLIFrameElement | null>(null);
const breadcrumbStore = useBreadcrumbStore();
const keys = useMagicKeys();

const lastKnownUrl = useSessionStorage('MENU_APP_IFRAME_SRC', '');
const debug = useLocalStorage('MENU_APP_DEBUG', false);
let breadCrumbsLoadedfrom = '';
whenever(keys.backquote, () => {
  debug.value = !debug.value;
});

function changedUrl() {
  const location = menuIframe.value?.contentWindow?.location;

  if (breadCrumbsLoadedfrom !== location?.href) {
    // Als er genavigeerd is en de breadcrumbs zijn nog geladen van een oude pagina dan de breadcrumbs leeg maken.
    breadcrumbStore.breadcrumbs = [];
  }

  if (!location) return;
  lastKnownUrl.value = location.pathname + location.search;
}

onMounted(async () => {
  // check if the menu is accidentally rendered in an iFrame
  if (inFrame()) {
    logger.warn('The menu-app does not allow itself to be rendered in an iFrame');
    error.value = 'De applicatie heeft geblokkeerd dat het menu in een iFrame werd geladen.';
    return;
  }

  // only allow rendering the menu when we are sure the application is in a valid state
  canRender.value = true;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const redirect = urlSearchParams.get('redirect');
  if (redirect) {
    iFrameSrc.value = decodeURIComponent(redirect);
    urlSearchParams.delete('redirect');
    window.history.replaceState({}, document.title, window.location.pathname);
    return;
  }

  // during development, we might want to set the default url
  if (env.VUE_APP_BASE_URL_MENU) {
    iFrameSrc.value = env.VUE_APP_BASE_URL_MENU;
    return;
  }

  // pak de laatst bekende URL uit de sessie (dus als je terug navigeert uit een app)
  // en ga anders naar het dashboard
  iFrameSrc.value = lastKnownUrl.value ? lastKnownUrl.value : '/content/';
});

function inFrame() {
  try {
    logger.debug('Window top location pathname: ', window.top?.location.pathname);
    logger.debug('Base URL: ', env.BASE_URL);
    return window.self !== window.top && window.top?.location.pathname.startsWith(env.BASE_URL);
  } catch (e) {
    return true;
  }
}

const host = computed(() => window.location.origin);

const { copy, copied } = useClipboard();
function isWindowProxy(source: MessageEventSource | null): source is WindowProxy {
  return !!source && 'location' in source;
}

useEventListener(window, 'message', async event => {
  const { value, type } = event.data;
  if (type !== 'breadcrumb') return;
  if (value.count === 0) {
    breadcrumbStore.breadcrumbs = [];
    if (isWindowProxy(event.source)) {
      const { source } = event;
      breadCrumbsLoadedfrom = source.location.href;
    }
  }

  breadcrumbStore.breadcrumbs.push({
    ...value,
    name: htmlDecode(value.name),
    ...(!value.link.startsWith('/app/') && { target: 'menu-iframe' })
  });
});
</script>
<template>
  <div v-if="claims?.isInitialLoading === false && claims?.isSuccess === true">
    <VInfoBox v-if="error" type="danger" class="tw-m-3">
      <p class="tw-text-base">
        Er is een error opgetreden bij het laden van SUREnet. Klik <a href="/content">hier</a> om terug te gaan naar het
        dashboard.
      </p>
      <p></p>
      <details class="tw-text-xs">
        <summary>Aanvullende informatie</summary>
        <div v-html="error"></div>
      </details>
    </VInfoBox>
    <div v-if="!error && canRender">
      <AppLayout navigate-in-frame>
        <div class="tw-flex">
          <Transition name="slide-fade-in-from-top">
            <div
              class="tw-flex tw-w-full tw-justify-between tw-bg-grey-300 tw-p-1 tw-font-mono tw-text-grey-600"
              v-if="debug && claims?.isInternal">
              <div>
                {{ host + lastKnownUrl }}
                <Transition name="flip">
                  <span v-if="copied" class="tw-ml-3 tw-text-success">
                    <FontAwesomeIcon icon="fas fa-check" class="tw-mr-2" />Gekopieerd
                  </span>
                </Transition>
                <Transition name="flip">
                  <FontAwesomeIcon
                    v-if="!copied"
                    @click="copy(host + lastKnownUrl)"
                    class="tw-ml-3 tw-cursor-pointer"
                    icon="far fa-copy" />
                </Transition>
                <Transition name="flip">
                  <FontAwesomeIcon
                    v-if="!copied"
                    @click="copy(lastKnownUrl)"
                    class="tw-ml-6 tw-cursor-pointer"
                    icon="far fa-brackets-curly" />
                </Transition>
              </div>
              <div>
                <FontAwesomeIcon @click="debug = false" icon="far fa-times" class="tw-mr-1 tw-cursor-pointer" />
              </div>
            </div>
          </Transition>
        </div>
        <iframe
          @load="changedUrl()"
          title="content"
          name="menu-iframe"
          id="menu-iframe"
          ref="menuIframe"
          :src="iFrameSrc"
          :style="`
          width: 100%;
          height: calc(100dvh - var(--header-height));
          background: url(${loader}) no-repeat fixed center/2.5rem;`"></iframe>
      </AppLayout>
    </div>
  </div>
</template>

<style lang="scss">
@import '@assets/styles/app.scss';

html {
  overflow: hidden;
}
</style>

export type VPopoverTheme = 'primary' | 'dark';

const getBackgroundColor = (theme?: VPopoverTheme | undefined) => {
  switch (theme) {
    case 'dark':
      return 'tw-bg-primary-700';
    case 'primary':
    default:
      return 'tw-bg-white';
  }
};

const getTextColor = (theme?: VPopoverTheme | undefined) => {
  switch (theme) {
    case 'dark':
      return 'tw-text-white';
    case 'primary':
    default:
      return 'tw-text-black';
  }
};

const getShadow = (theme?: VPopoverTheme | undefined) => {
  switch (theme) {
    case 'dark':
      return 'tw-shadow-lg';
    case 'primary':
    default:
      return 'tw-shadow-sm';
  }
};

const getBorder = (theme?: VPopoverTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'tw-border tw-border-grey-200';
    default:
      return '';
  }
};

export const getPassthroughStyles = (theme?: VPopoverTheme) => ({
  root: {
    class: [getTextColor(theme), getBackgroundColor(theme), getShadow(theme)]
  },
  content: {
    class: [getBorder(theme)]
  }
});

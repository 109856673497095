export const skeleton = {
  root: ({ props }: Record<string, any>) => ({
    class: [
      'tw-overflow-hidden',
      {
        'tw-animate-pulse': props.animation !== 'none'
      },
      // Round
      { 'tw-rounded-full': props.shape === 'circle', 'tw-rounded-md': props.shape !== 'circle' },
      // Colors
      'tw-bg-grey-400/25'
    ]
  })
};

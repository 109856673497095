import { ParsedBreadcrumb } from '@shared/component';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useBreadcrumbStore = defineStore('breadcrumbStore', () => {
  const breadcrumbs = ref<ParsedBreadcrumb[]>([]);

  return {
    breadcrumbs
  };
});

<script lang="ts" setup>
import { OTable, OTableColumn } from '@oruga-ui/oruga-next';
import { VToolTip } from '@shared/component';
import { VSearch } from '@shared/form';
import { computed, ref } from 'vue';
import {
  useGebruikerSearch,
  CrmGebruikersGetGebruikersLikeStringDto as GebruikerDto
} from '@services/crm-internal/orval-api';

const emit = defineEmits<{ close: [] }>();

function selectGebruiker(gebruiker: GebruikerDto) {
  const url = `/content/beheer/intern/tussenpersonen/details/algemeen/medewerkers/muteren/?gebruikerid=${gebruiker.id}`;
  const iFrame = document.getElementById('menu-iframe');
  if (iFrame) {
    emit('close');
    (iFrame as HTMLIFrameElement).src = url;
  } else {
    window.location.href = url;
  }
}

const filter = ref('');

const { data: users } = useGebruikerSearch(
  computed(() => ({
    term: filter.value
  })),
  {
    query: {
      select: response => response.result,
      enabled: computed(() => !!filter.value)
    }
  }
);

async function searchUsers(searchTerm: string) {
  if (searchTerm.trim().length < 3) return;
  filter.value = searchTerm;
}

function cleanTel(telefoonNr: string) {
  return telefoonNr.replace(/-|\s/g, '');
}
</script>

<template>
  <div class="tw-p-8">
    <VSearch placeholder="Voer minstens 3 letters in om te zoeken..." @input="searchUsers" class="tw-mb-8" />
    <OTable
      :data="users"
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="small"
      default-sort="volledigeNaam"
      striped
      hoverable
      td-class="tw-cursor-pointer"
      scrollable
      @select="selectGebruiker">
      <OTableColumn field="volledigeNaam" label="Naam" sortable :width="150" v-slot="props">
        {{ props.row.volledigeNaam }}
      </OTableColumn>
      <OTableColumn field="advisorName" label="Kantoor" sortable :width="200" v-slot="props">
        {{ props.row.advisorName }} ({{ props.row.advisorId }})
      </OTableColumn>
      <OTableColumn field="email" label="Email" sortable :width="150" v-slot="props">
        <VToolTip :label="`mail naar: ${props.row.email}`">
          <div class="tw-max-w-52 tw-truncate">
            <a :href="`mailto:${props.row.email}`" @click.stop>
              {{ props.row.email }}
            </a>
          </div>
        </VToolTip>
      </OTableColumn>
      <OTableColumn field="telefoon" label="Telefoon" :width="100" v-slot="props">
        <VToolTip v-if="props.row.telefoonnummer" :label="`start gesprek: ${props.row.telefoonnummer}`">
          <a :href="`tel:${cleanTel(props.row.telefoonnummer)}`" @click.stop>
            {{ props.row.telefoonnummer }}
          </a>
        </VToolTip>
        <span v-else>-</span>
      </OTableColumn>
      <OTableColumn field="accountmanager" label="Accountmanager" sortable :width="100" v-slot="props">
        {{ props.row.accountmanager ?? '-' }}
      </OTableColumn>
      <OTableColumn field="beursBeschikbaar" label="Beurs" sortable :width="30" v-slot="props">
        {{ props.row.beursBeschikbaar ? 'Ja' : 'Nee' }}
      </OTableColumn>
      <template v-slot:empty> Geen gebruikers gevonden </template>
    </OTable>
  </div>
</template>

export const chip = {
  root: {
    class: ['tw-inline-flex', 'tw-items-center', 'tw-rounded-full', 'tw-text-white', 'tw-uppercase tw-font-bold']
  },
  image: {
    class: ['tw-w-6 tw-h-6 -tw-ml-2.5 tw-mr-2', 'tw-rounded-full']
  },
  icon: {
    class: 'tw-leading-6 tw-mr-1.5 tw-h-3.5'
  },
  label: {
    class: 'tw-mx-0'
  },
  removeIcon: {
    class: [
      'tw-rounded-md tw-leading-6',
      'tw-ml-2',
      'tw-w-4 tw-h-4',
      'tw-transition tw-duration-200 tw-ease-in-out',
      'tw-cursor-pointer'
    ]
  }
};

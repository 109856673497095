import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { logger } from '@tools-lib';

const connectionString = typeof config !== 'undefined' ? config.ai.connectionString : '';

logger.debug('Using following application insights connectionstring: ', connectionString);

export const applicationInsights = new ApplicationInsights({
  config: {
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableCorrelationHeaders: false,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    loggingLevelConsole: 2,
    enableAutoRouteTracking: false,
    enableDebug: true,
    enableDebugExceptions: true,
    connectionString
  }
});

export const { appInsights } = applicationInsights;

export const select = {
  root: ({ props, parent }: Record<string, any>) => ({
    class: [
      // Display and Position
      'tw-inline-flex',
      'tw-relative',
      // Shape
      { 'tw-rounded-md': parent.instance.$name !== 'InputGroup' },
      { 'first:tw-rounded-l-md tw-rounded-none last:tw-rounded-r-md': parent.instance.$name === 'InputGroup' },
      { 'tw-border-0 tw-border-y tw-border-l last:tw-border-r': parent.instance.$name === 'InputGroup' },
      { 'first:tw-ml-0 tw-ml-[-1px]': parent.instance.$name === 'InputGroup' && !props.showButtons },
      'tw-w-80 tw-max-w-full',
      // Color and Background
      'tw-text-grey-800',
      'tw-border tw-border-solid tw-border-grey-300',
      {
        'tw-bg-white': !props.disabled && !props.invalid,
        'tw-bg-grey-50': props.disabled,
        'tw-bg-red-100/50': props.invalid
      },
      // Invalid State
      { 'tw-border-red-500': props.invalid },
      // Transitions
      'tw-transition-all',
      'tw-duration-200',
      // States
      { 'hover:tw-border-surface-400': !props.invalid },
      // Misc
      'tw-cursor-pointer',
      'tw-select-none',
      { 'tw-bg-surface-200 tw-select-none tw-pointer-events-none tw-cursor-default': props.disabled }
    ]
  }),
  label: ({ props, parent }: Record<string, any>) => {
    return {
      class: [
        // Font
        'tw-leading-[normal]',
        // Display
        'tw-block',
        'tw-flex-auto',
        // Color and Background
        'tw-bg-transparent',
        'tw-border-0',
        {
          'tw-text-surface-800': props.modelValue != null,
          'tw-text-surface-400': props.modelValue == null
        },
        'placeholder:tw-text-surface-400',
        // Sizing and Spacing
        'tw-w-[1%]',
        'tw-py-2 tw-px-4 tw-pr-0',
        { 'tw-pr-7': props.showClear },
        // Shape
        'tw-rounded-none',
        // Transitions
        'tw-transition',
        'tw-duration-200',
        // States
        'focus:tw-outline-none focus:tw-shadow-none',
        // Filled State *for FloatLabel
        { filled: (!parent.instance ? '' : parent.instance.$name) === 'FloatLabel' && props.modelValue !== null },
        // Misc
        'tw-relative',
        'tw-cursor-pointer',
        'tw-overflow-hidden tw-overflow-ellipsis',
        'tw-whitespace-nowrap',
        'tw-appearance-none'
      ]
    };
  },
  dropdown: ({ props }: Record<string, any>) => ({
    class: [
      { 'tw-hidden': props.disabled },
      'tw-flex tw-items-center tw-justify-center',
      'tw-shrink-0',
      'tw-bg-transparent',
      'tw-text-surface-500',
      'tw-w-12',
      'tw-rounded-r-md'
    ]
  }),
  overlay: {
    class: [
      'tw-box-content',
      'tw-bg-white',
      'tw-border tw-border-solid tw-border-grey-200',
      'tw-rounded-lg',
      'tw-shadow'
    ]
  },
  listContainer: {
    class: ['tw-max-h-[200px]', 'tw-overflow-auto']
  },
  list: {
    class: 'tw-py-2 tw-list-none tw-m-0'
  },
  option: ({ context }: Record<string, any>) => ({
    class: [
      // Font
      'tw-leading-none',
      { 'tw-font-normal': !context.selected, 'tw-font-bold': context.selected },
      // Position
      'tw-relative',
      // Shape
      'tw-border-0',
      'tw-rounded-none',
      // Spacing
      'tw-m-0',
      'tw-py-2 tw-px-4',
      // Color
      {
        'tw-bg-primary-200': context.focused && !context.selected
      },
      {
        'tw-bg-primary tw-text-white': context.focused && context.selected
      },
      { 'tw-bg-transparent': !context.focused && context.selected },
      // States
      'hover:tw-bg-primary hover:tw-text-white',
      // Misc
      'tw-cursor-pointer',
      'tw-overflow-hidden',
      'tw-whitespace-nowrap',
      'tw-group/option'
    ]
  }),
  optionGroup: {
    class: ['tw-font-semibold', 'tw-m-0 tw-py-2 tw-px-3', 'tw-text-surface-400', 'tw-cursor-auto']
  },
  optionCheckIcon: 'tw-relative -tw-ms-1.5 tw-me-1.5 tw-text-surface-700 tw-w-4 tw-h-4',
  optionBlankIcon: 'tw-w-4 tw-h-4',
  emptyMessage: {
    class: ['tw-leading-none', 'tw-py-2 tw-px-3', 'tw-text-surface-800', 'tw-bg-transparent']
  },
  header: {
    class: [
      'tw-pt-2 tw-px-2 tw-pb-0',
      'tw-m-0',
      'tw-border-b-0',
      'tw-rounded-tl-md',
      'tw-rounded-tr-md',
      'tw-text-surface-700',
      'tw-bg-surface-0',
      'tw-border-surface-300'
    ]
  },
  clearIcon: {
    class: ['tw-text-surface-400', 'tw-absolute', 'tw-top-1/2', 'tw-right-12', '-tw-mt-2']
  },
  loadingIcon: {
    class: 'tw-text-surface-400 tw-animate-spin'
  },
  transition: {
    enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
    enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
    leaveToClass: 'tw-opacity-0'
  }
};

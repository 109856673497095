export const getSurenetUrl = (url: string, isWebmodule: boolean) => {
  if (url.startsWith('/app/') || isWebmodule) return url;

  return `/app/menu-app/?redirect=${encodeURIComponent(url)}`;
};

export function tryMenuNavigation(event: Event, url?: string) {
  const iframe = document.getElementById('menu-iframe');
  const newUrl = (event.currentTarget as HTMLAnchorElement)?.href || url;

  if (!iframe || !newUrl) {
    return false;
  }

  (iframe as HTMLIFrameElement).src = newUrl;
  event.preventDefault();
  return true;
}

<script setup lang="ts">
import { ref } from 'vue';
import { FontAwesomeIcon } from '@shared/plugin';
import VPopover from '../VPopover/VPopover.vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    active?: boolean;
    sticky?: boolean;
    infoIcon?: boolean;
  }>(),
  {
    active: true,
    sticky: false,
    infoIcon: false
  }
);

const popoverRef = ref<InstanceType<typeof VPopover>>();
</script>

<template>
  <div>
    <div @mouseenter="popoverRef?.show" @mouseleave="e => !props.sticky && popoverRef?.hide(e)">
      <slot></slot>
    </div>
    <VPopover v-bind="$attrs" theme="dark" ref="popoverRef" v-if="props.active" class="tw-max-w-[300px]">
      <div class="tw-flex tw-gap-2 tw-px-3 tw-py-2 tw-text-sm tw-text-white">
        <FontAwesomeIcon v-if="props.infoIcon" class="tw-mt-0.5 tw-text-base" icon="far fa-info-circle" />
        <div class="tw-whitespace-pre-wrap" v-if="!$slots.content">{{ props.label }}</div>
        <div>
          <slot name="content"></slot>
        </div>
      </div>
    </VPopover>
  </div>
</template>

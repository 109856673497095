<template>
  <div class="styled-html">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.styled-html {
  :deep() {
    h2,
    ol,
    p,
    ul {
      &:not(:last-child) {
        margin: 0 0 1rem;
      }
    }

    h2 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    ol {
      padding-left: 2ch;

      li {
        list-style: inherit;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-left: 1ch;
      list-style: none;

      li {
        padding-left: 1ch;

        &::marker {
          content: '\f054';
          font-family: 'Font Awesome 6 Pro';
          color: rgb(var(--primary-900-rgb));
        }
      }
    }
  }
}
</style>

import { ComputedRef, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { userQueries } from '@services/identity-api/queries/User';
import { FlattenedPermissions } from './types';

export function hasPermission(permission: FlattenedPermissions): ComputedRef<boolean | undefined> {
  const claims = useQuery({
    ...userQueries.getClaims,
    staleTime: 60 * 1000,
    retry: 10
  });
  return computed(() => claims.data.value?.data.some(t => t.type === 'Permission' && t.value === permission));
}

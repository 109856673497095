export const inputgroupaddon = {
  root: {
    class: [
      // Flex
      'tw-flex tw-items-center tw-justify-center',

      // Shape
      'first:tw-rounded-l-lg',
      'last:tw-rounded-r-lg',
      'tw-border-y',

      'last:tw-border-r',
      'tw-border-l',
      'tw-border-r-0',

      // Space
      'tw-p-2',

      // Size
      'tw-min-w-[2.5rem]',

      // Color
      'tw-bg-white',
      'tw-text-grey-400',
      'tw-border-grey-300'
    ]
  }
};

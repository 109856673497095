/* tslint:disable */
/* eslint-disable */
/**
 * Identity Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery
 */
export interface IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery
     */
    userId?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto
 */
export interface IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto>}
     * @memberof IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto
     */
    roles?: Array<IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto> | null;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto
 */
export interface IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto
     */
    roleName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionRoleDto
     */
    isInRole: boolean;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesAuthorisationRoleDto
 */
export interface IdentityApplicationFeaturesAuthorisationRoleDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthorisationRoleDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthorisationRoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesAuthorisationRoleDto
     */
    normalizedName?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesReportingGetLoggedInUsersDto
 */
export interface IdentityApplicationFeaturesReportingGetLoggedInUsersDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesReportingGetLoggedInUsersDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesReportingGetLoggedInUsersDto
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesReportingGetLoggedInUsersDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicationFeaturesReportingGetLoggedInUsersDto
     */
    gebruikerId: number;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
 */
export interface IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto {
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
     */
    tussenPersoon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto
     */
    initials?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityApplicationFeaturesUsersUserDto
 */
export interface IdentityApplicationFeaturesUsersUserDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    adviseurnummer: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    isEmployee: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicationFeaturesUsersUserDto
     */
    roleNames?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IdentityApplicationIdentityTemporaryUserLifetime {
    Regular = 'Regular',
    Extended = 'Extended'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum IdentityCommonConstantsPasswordResetReason {
    OldPasswordError = 'OldPasswordError',
    RequestedByUser = 'RequestedByUser'
}

/**
 * 
 * @export
 * @interface IdentityWebModelsAuthenticationLoginAsApiModel
 */
export interface IdentityWebModelsAuthenticationLoginAsApiModel {
    /**
     * 
     * @type {string}
     * @memberof IdentityWebModelsAuthenticationLoginAsApiModel
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityWebModelsAuthenticationLoginAsApiModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface IdentityWebModelsAuthenticationSendPasswordResetMailModel
 */
export interface IdentityWebModelsAuthenticationSendPasswordResetMailModel {
    /**
     * 
     * @type {string}
     * @memberof IdentityWebModelsAuthenticationSendPasswordResetMailModel
     */
    emailAddress: string;
    /**
     * 
     * @type {number}
     * @memberof IdentityWebModelsAuthenticationSendPasswordResetMailModel
     */
    gebruikerId: number;
}
/**
 * 
 * @export
 * @interface IdentityWebModelsLogInAsDto
 */
export interface IdentityWebModelsLogInAsDto {
    /**
     * 
     * @type {number}
     * @memberof IdentityWebModelsLogInAsDto
     */
    loginAsId: number;
}
/**
 * 
 * @export
 * @interface IdentityWebModelsUserUserClaimDto
 */
export interface IdentityWebModelsUserUserClaimDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityWebModelsUserUserClaimDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityWebModelsUserUserClaimDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface SystemSecurityClaimsClaim
 */
export interface SystemSecurityClaimsClaim {
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaim
     */
    issuer: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaim
     */
    originalIssuer: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SystemSecurityClaimsClaim
     */
    properties: { [key: string]: string; };
    /**
     * 
     * @type {SystemSecurityClaimsClaimsIdentity}
     * @memberof SystemSecurityClaimsClaim
     */
    subject: SystemSecurityClaimsClaimsIdentity;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaim
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaim
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaim
     */
    valueType: string;
}
/**
 * 
 * @export
 * @interface SystemSecurityClaimsClaimsIdentity
 */
export interface SystemSecurityClaimsClaimsIdentity {
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    authenticationType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    isAuthenticated: boolean;
    /**
     * 
     * @type {SystemSecurityClaimsClaimsIdentity}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    actor: SystemSecurityClaimsClaimsIdentity;
    /**
     * 
     * @type {any}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    bootstrapContext?: any | null;
    /**
     * 
     * @type {Array<SystemSecurityClaimsClaim>}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    claims: Array<SystemSecurityClaimsClaim>;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    nameClaimType: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSecurityClaimsClaimsIdentity
     */
    roleClaimType: string;
}

/**
 * FeatureFlagApi - axios parameter creator
 * @export
 */
export const FeatureFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns list of feature flags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FeaturesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureFlagApi - functional programming interface
 * @export
 */
export const FeatureFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns list of feature flags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FeaturesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FeaturesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureFlagApi - factory interface
 * @export
 */
export const FeatureFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns list of feature flags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FeaturesGet(options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.apiV1FeaturesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureFlagApi - interface
 * @export
 * @interface FeatureFlagApi
 */
export interface FeatureFlagApiInterface {
    /**
     * 
     * @summary Returns list of feature flags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApiInterface
     */
    apiV1FeaturesGet(options?: any): AxiosPromise<{ [key: string]: boolean; }>;

}

/**
 * FeatureFlagApi - object-oriented interface
 * @export
 * @class FeatureFlagApi
 * @extends {BaseAPI}
 */
export class FeatureFlagApi extends BaseAPI implements FeatureFlagApiInterface {
    /**
     * 
     * @summary Returns list of feature flags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public apiV1FeaturesGet(options?: any) {
        return FeatureFlagApiFp(this.configuration).apiV1FeaturesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForgotPasswordApi - axios parameter creator
 * @export
 */
export const ForgotPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotGet: async (userName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Password/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotPost: async (userName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Password/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgotPasswordApi - functional programming interface
 * @export
 */
export const ForgotPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgotPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordForgotGet(userName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordForgotGet(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordForgotPost(userName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordForgotPost(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgotPasswordApi - factory interface
 * @export
 */
export const ForgotPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgotPasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotGet(userName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.passwordForgotGet(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotPost(userName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.passwordForgotPost(userName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgotPasswordApi - interface
 * @export
 * @interface ForgotPasswordApi
 */
export interface ForgotPasswordApiInterface {
    /**
     * 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApiInterface
     */
    passwordForgotGet(userName?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApiInterface
     */
    passwordForgotPost(userName?: string, options?: any): AxiosPromise<void>;

}

/**
 * ForgotPasswordApi - object-oriented interface
 * @export
 * @class ForgotPasswordApi
 * @extends {BaseAPI}
 */
export class ForgotPasswordApi extends BaseAPI implements ForgotPasswordApiInterface {
    /**
     * 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApi
     */
    public passwordForgotGet(userName?: string, options?: any) {
        return ForgotPasswordApiFp(this.configuration).passwordForgotGet(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApi
     */
    public passwordForgotPost(userName?: string, options?: any) {
        return ForgotPasswordApiFp(this.configuration).passwordForgotPost(userName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForgotUsernameApi - axios parameter creator
 * @export
 */
export const ForgotUsernameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usernameForgotGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Username/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usernameForgotPost: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usernameForgotPost', 'email', email)
            const localVarPath = `/Username/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgotUsernameApi - functional programming interface
 * @export
 */
export const ForgotUsernameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgotUsernameApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usernameForgotGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usernameForgotGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usernameForgotPost(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usernameForgotPost(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgotUsernameApi - factory interface
 * @export
 */
export const ForgotUsernameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgotUsernameApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usernameForgotGet(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.usernameForgotGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usernameForgotPost(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.usernameForgotPost(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgotUsernameApi - interface
 * @export
 * @interface ForgotUsernameApi
 */
export interface ForgotUsernameApiInterface {
    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotUsernameApiInterface
     */
    usernameForgotGet(email?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotUsernameApiInterface
     */
    usernameForgotPost(email: string, options?: any): AxiosPromise<void>;

}

/**
 * ForgotUsernameApi - object-oriented interface
 * @export
 * @class ForgotUsernameApi
 * @extends {BaseAPI}
 */
export class ForgotUsernameApi extends BaseAPI implements ForgotUsernameApiInterface {
    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotUsernameApi
     */
    public usernameForgotGet(email?: string, options?: any) {
        return ForgotUsernameApiFp(this.configuration).usernameForgotGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotUsernameApi
     */
    public usernameForgotPost(email: string, options?: any) {
        return ForgotUsernameApiFp(this.configuration).usernameForgotPost(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginAsApi - axios parameter creator
 * @export
 */
export const LoginAsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login the user.
         * @param {IdentityWebModelsAuthenticationLoginAsApiModel} [identityWebModelsAuthenticationLoginAsApiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (identityWebModelsAuthenticationLoginAsApiModel?: IdentityWebModelsAuthenticationLoginAsApiModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityWebModelsAuthenticationLoginAsApiModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityWebModelsLogInAsDto} [identityWebModelsLogInAsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsLoginAsTPUserPost: async (identityWebModelsLogInAsDto?: IdentityWebModelsLogInAsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LoginAs/LoginAsTPUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityWebModelsLogInAsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginAsApi - functional programming interface
 * @export
 */
export const LoginAsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginAsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login the user.
         * @param {IdentityWebModelsAuthenticationLoginAsApiModel} [identityWebModelsAuthenticationLoginAsApiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(identityWebModelsAuthenticationLoginAsApiModel?: IdentityWebModelsAuthenticationLoginAsApiModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(identityWebModelsAuthenticationLoginAsApiModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityWebModelsLogInAsDto} [identityWebModelsLogInAsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto?: IdentityWebModelsLogInAsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginAsApi - factory interface
 * @export
 */
export const LoginAsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginAsApiFp(configuration)
    return {
        /**
         * 
         * @summary Login the user.
         * @param {IdentityWebModelsAuthenticationLoginAsApiModel} [identityWebModelsAuthenticationLoginAsApiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(identityWebModelsAuthenticationLoginAsApiModel?: IdentityWebModelsAuthenticationLoginAsApiModel, options?: any): AxiosPromise<void> {
            return localVarFp.login(identityWebModelsAuthenticationLoginAsApiModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityWebModelsLogInAsDto} [identityWebModelsLogInAsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto?: IdentityWebModelsLogInAsDto, options?: any): AxiosPromise<string> {
            return localVarFp.loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginAsApi - interface
 * @export
 * @interface LoginAsApi
 */
export interface LoginAsApiInterface {
    /**
     * 
     * @summary Login the user.
     * @param {IdentityWebModelsAuthenticationLoginAsApiModel} [identityWebModelsAuthenticationLoginAsApiModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginAsApiInterface
     */
    login(identityWebModelsAuthenticationLoginAsApiModel?: IdentityWebModelsAuthenticationLoginAsApiModel, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {IdentityWebModelsLogInAsDto} [identityWebModelsLogInAsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginAsApiInterface
     */
    loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto?: IdentityWebModelsLogInAsDto, options?: any): AxiosPromise<string>;

}

/**
 * LoginAsApi - object-oriented interface
 * @export
 * @class LoginAsApi
 * @extends {BaseAPI}
 */
export class LoginAsApi extends BaseAPI implements LoginAsApiInterface {
    /**
     * 
     * @summary Login the user.
     * @param {IdentityWebModelsAuthenticationLoginAsApiModel} [identityWebModelsAuthenticationLoginAsApiModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginAsApi
     */
    public login(identityWebModelsAuthenticationLoginAsApiModel?: IdentityWebModelsAuthenticationLoginAsApiModel, options?: any) {
        return LoginAsApiFp(this.configuration).login(identityWebModelsAuthenticationLoginAsApiModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityWebModelsLogInAsDto} [identityWebModelsLogInAsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginAsApi
     */
    public loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto?: IdentityWebModelsLogInAsDto, options?: any) {
        return LoginAsApiFp(this.configuration).loginAsLoginAsTPUserPost(identityWebModelsLogInAsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordResetApi - axios parameter creator
 * @export
 */
export const PasswordResetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [passwordResetToken] 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetGet: async (userId?: number, passwordResetToken?: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (passwordResetToken !== undefined) {
                localVarQueryParameter['passwordResetToken'] = passwordResetToken;
            }

            if (passwordResetReason !== undefined) {
                localVarQueryParameter['passwordResetReason'] = passwordResetReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} password 
         * @param {string} confirmPassword 
         * @param {number} userId 
         * @param {string} token 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPost: async (password: string, confirmPassword: string, userId: number, token: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('passwordResetPost', 'password', password)
            // verify required parameter 'confirmPassword' is not null or undefined
            assertParamExists('passwordResetPost', 'confirmPassword', confirmPassword)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('passwordResetPost', 'userId', userId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('passwordResetPost', 'token', token)
            const localVarPath = `/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (password !== undefined) {
                localVarQueryParameter['Password'] = password;
            }

            if (confirmPassword !== undefined) {
                localVarQueryParameter['ConfirmPassword'] = confirmPassword;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (passwordResetReason !== undefined) {
                localVarQueryParameter['PasswordResetReason'] = passwordResetReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetApi - functional programming interface
 * @export
 */
export const PasswordResetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [passwordResetToken] 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetGet(userId?: number, passwordResetToken?: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetGet(userId, passwordResetToken, passwordResetReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} password 
         * @param {string} confirmPassword 
         * @param {number} userId 
         * @param {string} token 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetPost(password: string, confirmPassword: string, userId: number, token: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetPost(password, confirmPassword, userId, token, passwordResetReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordResetApi - factory interface
 * @export
 */
export const PasswordResetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [passwordResetToken] 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetGet(userId?: number, passwordResetToken?: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): AxiosPromise<void> {
            return localVarFp.passwordResetGet(userId, passwordResetToken, passwordResetReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} password 
         * @param {string} confirmPassword 
         * @param {number} userId 
         * @param {string} token 
         * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPost(password: string, confirmPassword: string, userId: number, token: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): AxiosPromise<void> {
            return localVarFp.passwordResetPost(password, confirmPassword, userId, token, passwordResetReason, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetApi - interface
 * @export
 * @interface PasswordResetApi
 */
export interface PasswordResetApiInterface {
    /**
     * 
     * @param {number} [userId] 
     * @param {string} [passwordResetToken] 
     * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApiInterface
     */
    passwordResetGet(userId?: number, passwordResetToken?: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} password 
     * @param {string} confirmPassword 
     * @param {number} userId 
     * @param {string} token 
     * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApiInterface
     */
    passwordResetPost(password: string, confirmPassword: string, userId: number, token: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any): AxiosPromise<void>;

}

/**
 * PasswordResetApi - object-oriented interface
 * @export
 * @class PasswordResetApi
 * @extends {BaseAPI}
 */
export class PasswordResetApi extends BaseAPI implements PasswordResetApiInterface {
    /**
     * 
     * @param {number} [userId] 
     * @param {string} [passwordResetToken] 
     * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public passwordResetGet(userId?: number, passwordResetToken?: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any) {
        return PasswordResetApiFp(this.configuration).passwordResetGet(userId, passwordResetToken, passwordResetReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} password 
     * @param {string} confirmPassword 
     * @param {number} userId 
     * @param {string} token 
     * @param {IdentityCommonConstantsPasswordResetReason} [passwordResetReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public passwordResetPost(password: string, confirmPassword: string, userId: number, token: string, passwordResetReason?: IdentityCommonConstantsPasswordResetReason, options?: any) {
        return PasswordResetApiFp(this.configuration).passwordResetPost(password, confirmPassword, userId, token, passwordResetReason, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of the logged in users in Identity.
         * @param {number} [lastActivityInMinutes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LoggedInUsersLoginsGet: async (lastActivityInMinutes?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logged-in-users/logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lastActivityInMinutes !== undefined) {
                localVarQueryParameter['lastActivityInMinutes'] = lastActivityInMinutes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of the logged in users in Identity.
         * @param {number} [lastActivityInMinutes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LoggedInUsersLoginsGet(lastActivityInMinutes?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesReportingGetLoggedInUsersDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LoggedInUsersLoginsGet(lastActivityInMinutes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of the logged in users in Identity.
         * @param {number} [lastActivityInMinutes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LoggedInUsersLoginsGet(lastActivityInMinutes?: number, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesReportingGetLoggedInUsersDto>> {
            return localVarFp.apiV1LoggedInUsersLoginsGet(lastActivityInMinutes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingApi - interface
 * @export
 * @interface ReportingApi
 */
export interface ReportingApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns the list of the logged in users in Identity.
     * @param {number} [lastActivityInMinutes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApiInterface
     */
    apiV1LoggedInUsersLoginsGet(lastActivityInMinutes?: number, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesReportingGetLoggedInUsersDto>>;

}

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI implements ReportingApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns the list of the logged in users in Identity.
     * @param {number} [lastActivityInMinutes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiV1LoggedInUsersLoginsGet(lastActivityInMinutes?: number, options?: any) {
        return ReportingApiFp(this.configuration).apiV1LoggedInUsersLoginsGet(lastActivityInMinutes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.RollenInzien</li></ul>
         * @summary Returns a list of all available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.RollenInzien</li></ul>
         * @summary Returns a list of all available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.RollenInzien</li></ul>
         * @summary Returns a list of all available roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>> {
            return localVarFp.apiV1RoleGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - interface
 * @export
 * @interface RoleApi
 */
export interface RoleApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.RollenInzien</li></ul>
     * @summary Returns a list of all available roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    apiV1RoleGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>>;

}

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI implements RoleApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.RollenInzien</li></ul>
     * @summary Returns a list of all available roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleGet(options?: any) {
        return RoleApiFp(this.configuration).apiV1RoleGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleMatrixApi - axios parameter creator
 * @export
 */
export const RoleMatrixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.PermissiesInzien</li></ul>
         * @summary Returns a list of all permissins and their associated roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RolematrixGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rolematrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleMatrixApi - functional programming interface
 * @export
 */
export const RoleMatrixApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleMatrixApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.PermissiesInzien</li></ul>
         * @summary Returns a list of all permissins and their associated roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RolematrixGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RolematrixGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleMatrixApi - factory interface
 * @export
 */
export const RoleMatrixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleMatrixApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.PermissiesInzien</li></ul>
         * @summary Returns a list of all permissins and their associated roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RolematrixGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto>> {
            return localVarFp.apiV1RolematrixGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleMatrixApi - interface
 * @export
 * @interface RoleMatrixApi
 */
export interface RoleMatrixApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.PermissiesInzien</li></ul>
     * @summary Returns a list of all permissins and their associated roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleMatrixApiInterface
     */
    apiV1RolematrixGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationGetRolesForPermissionsPermissionDto>>;

}

/**
 * RoleMatrixApi - object-oriented interface
 * @export
 * @class RoleMatrixApi
 * @extends {BaseAPI}
 */
export class RoleMatrixApi extends BaseAPI implements RoleMatrixApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.PermissiesInzien</li></ul>
     * @summary Returns a list of all permissins and their associated roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleMatrixApi
     */
    public apiV1RolematrixGet(options?: any) {
        return RoleMatrixApiFp(this.configuration).apiV1RolematrixGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SendResetEmailApiApi - axios parameter creator
 * @export
 */
export const SendResetEmailApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IdentityWebModelsAuthenticationSendPasswordResetMailModel} [identityWebModelsAuthenticationSendPasswordResetMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SendresetemailPost: async (identityWebModelsAuthenticationSendPasswordResetMailModel?: IdentityWebModelsAuthenticationSendPasswordResetMailModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sendresetemail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityWebModelsAuthenticationSendPasswordResetMailModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SendResetEmailApiApi - functional programming interface
 * @export
 */
export const SendResetEmailApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SendResetEmailApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IdentityWebModelsAuthenticationSendPasswordResetMailModel} [identityWebModelsAuthenticationSendPasswordResetMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel?: IdentityWebModelsAuthenticationSendPasswordResetMailModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SendResetEmailApiApi - factory interface
 * @export
 */
export const SendResetEmailApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SendResetEmailApiApiFp(configuration)
    return {
        /**
         * 
         * @param {IdentityWebModelsAuthenticationSendPasswordResetMailModel} [identityWebModelsAuthenticationSendPasswordResetMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel?: IdentityWebModelsAuthenticationSendPasswordResetMailModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SendResetEmailApiApi - interface
 * @export
 * @interface SendResetEmailApiApi
 */
export interface SendResetEmailApiApiInterface {
    /**
     * 
     * @param {IdentityWebModelsAuthenticationSendPasswordResetMailModel} [identityWebModelsAuthenticationSendPasswordResetMailModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendResetEmailApiApiInterface
     */
    apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel?: IdentityWebModelsAuthenticationSendPasswordResetMailModel, options?: any): AxiosPromise<void>;

}

/**
 * SendResetEmailApiApi - object-oriented interface
 * @export
 * @class SendResetEmailApiApi
 * @extends {BaseAPI}
 */
export class SendResetEmailApiApi extends BaseAPI implements SendResetEmailApiApiInterface {
    /**
     * 
     * @param {IdentityWebModelsAuthenticationSendPasswordResetMailModel} [identityWebModelsAuthenticationSendPasswordResetMailModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendResetEmailApiApi
     */
    public apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel?: IdentityWebModelsAuthenticationSendPasswordResetMailModel, options?: any) {
        return SendResetEmailApiApiFp(this.configuration).apiV1SendresetemailPost(identityWebModelsAuthenticationSendPasswordResetMailModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionValidateCookieSessionPost: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/session/ValidateCookieSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionValidatesessionPost: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/session/validatesession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionValidateCookieSessionPost(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemSecurityClaimsClaim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionValidateCookieSessionPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionValidatesessionPost(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemSecurityClaimsClaim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionValidatesessionPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery>> {
            return localVarFp.apiV1SessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionValidateCookieSessionPost(body?: string, options?: any): AxiosPromise<Array<SystemSecurityClaimsClaim>> {
            return localVarFp.apiV1SessionValidateCookieSessionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionValidatesessionPost(body?: string, options?: any): AxiosPromise<Array<SystemSecurityClaimsClaim>> {
            return localVarFp.apiV1SessionValidatesessionPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - interface
 * @export
 * @interface SessionApi
 */
export interface SessionApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApiInterface
     */
    apiV1SessionGet(options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthenticationTicketsGetAuthenticationSessionsQuery>>;

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApiInterface
     */
    apiV1SessionValidateCookieSessionPost(body?: string, options?: any): AxiosPromise<Array<SystemSecurityClaimsClaim>>;

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApiInterface
     */
    apiV1SessionValidatesessionPost(body?: string, options?: any): AxiosPromise<Array<SystemSecurityClaimsClaim>>;

}

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI implements SessionApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionGet(options?: any) {
        return SessionApiFp(this.configuration).apiV1SessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionValidateCookieSessionPost(body?: string, options?: any) {
        return SessionApiFp(this.configuration).apiV1SessionValidateCookieSessionPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionValidatesessionPost(body?: string, options?: any) {
        return SessionApiFp(this.configuration).apiV1SessionValidatesessionPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TijdelijkeGebruikerApi - axios parameter creator
 * @export
 */
export const TijdelijkeGebruikerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} gebruikerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGebruikerIdRevokePost: async (gebruikerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gebruikerId' is not null or undefined
            assertParamExists('apiV1TempUserGebruikerIdRevokePost', 'gebruikerId', gebruikerId)
            const localVarPath = `/api/v1/temp-user/{gebruikerId}/revoke`
                .replace(`{${"gebruikerId"}}`, encodeURIComponent(String(gebruikerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {string} [email] 
         * @param {string} [returnUrl] 
         * @param {IdentityApplicationIdentityTemporaryUserLifetime} [lifetime] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGeneratePost: async (adviseurnummer?: number, email?: string, returnUrl?: string, lifetime?: IdentityApplicationIdentityTemporaryUserLifetime, requestBody?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/temp-user/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adviseurnummer !== undefined) {
                localVarQueryParameter['adviseurnummer'] = adviseurnummer;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }

            if (lifetime !== undefined) {
                localVarQueryParameter['lifetime'] = lifetime;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/temp-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [userId] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserLoginGet: async (token?: string, userId?: string, returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/temp-user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TijdelijkeGebruikerApi - functional programming interface
 * @export
 */
export const TijdelijkeGebruikerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TijdelijkeGebruikerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} gebruikerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TempUserGebruikerIdRevokePost(gebruikerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TempUserGebruikerIdRevokePost(gebruikerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {string} [email] 
         * @param {string} [returnUrl] 
         * @param {IdentityApplicationIdentityTemporaryUserLifetime} [lifetime] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TempUserGeneratePost(adviseurnummer?: number, email?: string, returnUrl?: string, lifetime?: IdentityApplicationIdentityTemporaryUserLifetime, requestBody?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TempUserGeneratePost(adviseurnummer, email, returnUrl, lifetime, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TempUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TempUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [userId] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TempUserLoginGet(token?: string, userId?: string, returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TempUserLoginGet(token, userId, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TijdelijkeGebruikerApi - factory interface
 * @export
 */
export const TijdelijkeGebruikerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TijdelijkeGebruikerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} gebruikerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGebruikerIdRevokePost(gebruikerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TempUserGebruikerIdRevokePost(gebruikerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {string} [email] 
         * @param {string} [returnUrl] 
         * @param {IdentityApplicationIdentityTemporaryUserLifetime} [lifetime] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGeneratePost(adviseurnummer?: number, email?: string, returnUrl?: string, lifetime?: IdentityApplicationIdentityTemporaryUserLifetime, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TempUserGeneratePost(adviseurnummer, email, returnUrl, lifetime, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TempUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [userId] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TempUserLoginGet(token?: string, userId?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TempUserLoginGet(token, userId, returnUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TijdelijkeGebruikerApi - interface
 * @export
 * @interface TijdelijkeGebruikerApi
 */
export interface TijdelijkeGebruikerApiInterface {
    /**
     * 
     * @param {number} gebruikerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApiInterface
     */
    apiV1TempUserGebruikerIdRevokePost(gebruikerId: number, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {number} [adviseurnummer] 
     * @param {string} [email] 
     * @param {string} [returnUrl] 
     * @param {IdentityApplicationIdentityTemporaryUserLifetime} [lifetime] 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApiInterface
     */
    apiV1TempUserGeneratePost(adviseurnummer?: number, email?: string, returnUrl?: string, lifetime?: IdentityApplicationIdentityTemporaryUserLifetime, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApiInterface
     */
    apiV1TempUserGet(options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} [token] 
     * @param {string} [userId] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApiInterface
     */
    apiV1TempUserLoginGet(token?: string, userId?: string, returnUrl?: string, options?: any): AxiosPromise<void>;

}

/**
 * TijdelijkeGebruikerApi - object-oriented interface
 * @export
 * @class TijdelijkeGebruikerApi
 * @extends {BaseAPI}
 */
export class TijdelijkeGebruikerApi extends BaseAPI implements TijdelijkeGebruikerApiInterface {
    /**
     * 
     * @param {number} gebruikerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApi
     */
    public apiV1TempUserGebruikerIdRevokePost(gebruikerId: number, options?: any) {
        return TijdelijkeGebruikerApiFp(this.configuration).apiV1TempUserGebruikerIdRevokePost(gebruikerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [adviseurnummer] 
     * @param {string} [email] 
     * @param {string} [returnUrl] 
     * @param {IdentityApplicationIdentityTemporaryUserLifetime} [lifetime] 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApi
     */
    public apiV1TempUserGeneratePost(adviseurnummer?: number, email?: string, returnUrl?: string, lifetime?: IdentityApplicationIdentityTemporaryUserLifetime, requestBody?: { [key: string]: string; }, options?: any) {
        return TijdelijkeGebruikerApiFp(this.configuration).apiV1TempUserGeneratePost(adviseurnummer, email, returnUrl, lifetime, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApi
     */
    public apiV1TempUserGet(options?: any) {
        return TijdelijkeGebruikerApiFp(this.configuration).apiV1TempUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [userId] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TijdelijkeGebruikerApi
     */
    public apiV1TempUserLoginGet(token?: string, userId?: string, returnUrl?: string, options?: any) {
        return TijdelijkeGebruikerApiFp(this.configuration).apiV1TempUserLoginGet(token, userId, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangeGet: async (returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Password/Change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangeGet(returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangeGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangeGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.passwordChangeGet(returnUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    passwordChangeGet(returnUrl?: string, options?: any): AxiosPromise<void>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public passwordChangeGet(returnUrl?: string, options?: any) {
        return UserApiFp(this.configuration).passwordChangeGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApiApi - axios parameter creator
 * @export
 */
export const UserApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserClaimsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of all (internal) users present in Identity
         * @param {boolean} [onlyInternal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGet: async (onlyInternal?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyInternal !== undefined) {
                localVarQueryParameter['onlyInternal'] = onlyInternal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserProfileGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a identity user and marks the SUREnet user as removed.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdDelete: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UserUserIdDelete', 'userId', userId)
            const localVarPath = `/api/v1/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a single Identity user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UserUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApiApi - functional programming interface
 * @export
 */
export const UserApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserClaimsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityWebModelsUserUserClaimDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserClaimsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of all (internal) users present in Identity
         * @param {boolean} [onlyInternal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserGet(onlyInternal?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesUsersUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserGet(onlyInternal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserProfileGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a identity user and marks the SUREnet user as removed.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUserIdDelete(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a single Identity user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityApplicationFeaturesUsersUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApiApi - factory interface
 * @export
 */
export const UserApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserClaimsGet(options?: any): AxiosPromise<Array<IdentityWebModelsUserUserClaimDto>> {
            return localVarFp.apiV1UserClaimsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns the list of all (internal) users present in Identity
         * @param {boolean} [onlyInternal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGet(onlyInternal?: boolean, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesUsersUserDto>> {
            return localVarFp.apiV1UserGet(onlyInternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserProfileGet(options?: any): AxiosPromise<IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto> {
            return localVarFp.apiV1UserProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a identity user and marks the SUREnet user as removed.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a single Identity user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdGet(userId: string, options?: any): AxiosPromise<IdentityApplicationFeaturesUsersUserDto> {
            return localVarFp.apiV1UserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApiApi - interface
 * @export
 * @interface UserApiApi
 */
export interface UserApiApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApiInterface
     */
    apiV1UserClaimsGet(options?: any): AxiosPromise<Array<IdentityWebModelsUserUserClaimDto>>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns the list of all (internal) users present in Identity
     * @param {boolean} [onlyInternal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApiInterface
     */
    apiV1UserGet(onlyInternal?: boolean, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesUsersUserDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApiInterface
     */
    apiV1UserProfileGet(options?: any): AxiosPromise<IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto>;

    /**
     * 
     * @summary Deletes a identity user and marks the SUREnet user as removed.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApiInterface
     */
    apiV1UserUserIdDelete(userId: string, options?: any): AxiosPromise<void>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns a single Identity user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApiInterface
     */
    apiV1UserUserIdGet(userId: string, options?: any): AxiosPromise<IdentityApplicationFeaturesUsersUserDto>;

}

/**
 * UserApiApi - object-oriented interface
 * @export
 * @class UserApiApi
 * @extends {BaseAPI}
 */
export class UserApiApi extends BaseAPI implements UserApiApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public apiV1UserClaimsGet(options?: any) {
        return UserApiApiFp(this.configuration).apiV1UserClaimsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns the list of all (internal) users present in Identity
     * @param {boolean} [onlyInternal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public apiV1UserGet(onlyInternal?: boolean, options?: any) {
        return UserApiApiFp(this.configuration).apiV1UserGet(onlyInternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public apiV1UserProfileGet(options?: any) {
        return UserApiApiFp(this.configuration).apiV1UserProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a identity user and marks the SUREnet user as removed.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public apiV1UserUserIdDelete(userId: string, options?: any) {
        return UserApiApiFp(this.configuration).apiV1UserUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns a single Identity user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public apiV1UserUserIdGet(userId: string, options?: any) {
        return UserApiApiFp(this.configuration).apiV1UserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRoleApi - axios parameter creator
 * @export
 */
export const UserRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a list of all assigned roles of the user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UserUserIdRoleGet', 'userId', userId)
            const localVarPath = `/api/v1/user/{userId}/role`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Removes a role assignment from user.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleRoleIdDelete: async (userId: string, roleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UserUserIdRoleRoleIdDelete', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1UserUserIdRoleRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/api/v1/user/{userId}/role/{roleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Add a user to a specific role.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleRoleIdPost: async (userId: string, roleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UserUserIdRoleRoleIdPost', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1UserUserIdRoleRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/v1/user/{userId}/role/{roleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRoleApi - functional programming interface
 * @export
 */
export const UserRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRoleApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a list of all assigned roles of the user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUserIdRoleGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUserIdRoleGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Removes a role assignment from user.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUserIdRoleRoleIdDelete(userId: string, roleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUserIdRoleRoleIdDelete(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Add a user to a specific role.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUserIdRoleRoleIdPost(userId: string, roleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUserIdRoleRoleIdPost(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRoleApi - factory interface
 * @export
 */
export const UserRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRoleApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
         * @summary Returns a list of all assigned roles of the user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleGet(userId: string, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>> {
            return localVarFp.apiV1UserUserIdRoleGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Removes a role assignment from user.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleRoleIdDelete(userId: string, roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserUserIdRoleRoleIdDelete(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
         * @summary Add a user to a specific role.
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUserIdRoleRoleIdPost(userId: string, roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserUserIdRoleRoleIdPost(userId, roleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRoleApi - interface
 * @export
 * @interface UserRoleApi
 */
export interface UserRoleApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns a list of all assigned roles of the user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApiInterface
     */
    apiV1UserUserIdRoleGet(userId: string, options?: any): AxiosPromise<Array<IdentityApplicationFeaturesAuthorisationRoleDto>>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
     * @summary Removes a role assignment from user.
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApiInterface
     */
    apiV1UserUserIdRoleRoleIdDelete(userId: string, roleId: string, options?: any): AxiosPromise<void>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
     * @summary Add a user to a specific role.
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApiInterface
     */
    apiV1UserUserIdRoleRoleIdPost(userId: string, roleId: string, options?: any): AxiosPromise<void>;

}

/**
 * UserRoleApi - object-oriented interface
 * @export
 * @class UserRoleApi
 * @extends {BaseAPI}
 */
export class UserRoleApi extends BaseAPI implements UserRoleApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersInzien</li></ul>
     * @summary Returns a list of all assigned roles of the user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public apiV1UserUserIdRoleGet(userId: string, options?: any) {
        return UserRoleApiFp(this.configuration).apiV1UserUserIdRoleGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
     * @summary Removes a role assignment from user.
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public apiV1UserUserIdRoleRoleIdDelete(userId: string, roleId: string, options?: any) {
        return UserRoleApiFp(this.configuration).apiV1UserUserIdRoleRoleIdDelete(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Autorisatie.GebruikersRollenWijzigen</li></ul>
     * @summary Add a user to a specific role.
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public apiV1UserUserIdRoleRoleIdPost(userId: string, roleId: string, options?: any) {
        return UserRoleApiFp(this.configuration).apiV1UserUserIdRoleRoleIdPost(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }
}



import { App, InjectionKey } from 'vue';
import { Router } from 'vue-router';
import { AxiosStatic } from 'axios';
import { userApi } from '@services/identity-api/identityService';
import { initApplicationInsight } from '@tools-lib';

import {
  clearUserContextMethod,
  dependencyMethod,
  setupPageTracking,
  setUserContextMethod,
  trackEventMethod,
  trackException,
  UserContextParams
} from './aiUtils';

import { applicationInsights, appInsights } from './applicationInsights';

export interface AiTracker {
  setUserContext: (payload: UserContextParams) => void;
  clearUserContext: () => void;
  trackEvent: (payload: any) => void;
}
export interface ApplicationInsightsOptions {
  applicationName: string;
  router?: Router;
  trackInitialPageView: boolean;
  axios?: AxiosStatic;
  trackingConfiguration?: any;
}

export const injectionKey: InjectionKey<AiTracker> = Symbol('AiTracker');

async function TryGetUserEmail() {
  try {
    const res = await userApi.apiV1UserProfileGet();
    return res.data?.email ?? 'onbekend';
  } catch (e) {
    console.warn('Failed to fetch user');
    return 'onbekend@onbekend.nl';
  }
}

const plugin = {
  async install(app: App, options: ApplicationInsightsOptions) {
    // --------------------------------------------------- installation
    const { trackingConfiguration, applicationName } = options;
    app.config.globalProperties.trackingConfiguration = trackingConfiguration;
    applicationInsights.loadAppInsights();

    initApplicationInsight(applicationInsights);
    // ---------------------------------------------------- router watch
    const { router, axios = null } = options;
    if (axios) {
      axios.interceptors.response.use(
        config => config,
        error => {
          dependencyMethod(error?.response);
        }
      );
    }

    // Watch route event if router option is defined.
    if (router) {
      if (options.trackInitialPageView !== false) {
        setupPageTracking(applicationName, router);
      } else {
        await router.isReady();
        setupPageTracking(applicationName, router);
      }
    }

    appInsights.addTelemetryInitializer(envelope => {
      if (envelope.tags != null) {
        envelope.tags['ai.cloud.role'] = `${options.applicationName}`;
        envelope.tags['ai.cloud.roleInstance'] = `${config.ai.roleName}`;
      }
    });

    // -------------------------------------------------- track exceptions
    app.config.errorHandler = error => {
      trackException(error);
    };

    const aiTracker: AiTracker = {
      trackEvent: (payload: any) => trackEventMethod(payload, trackingConfiguration),
      setUserContext: (payload: UserContextParams) => setUserContextMethod(payload),
      clearUserContext: () => clearUserContextMethod()
    };

    app.provide(injectionKey, aiTracker);
    const email = await TryGetUserEmail();

    setUserContextMethod({ authenticatedUserId: email, accountId: '1', storeInCookie: true });
  }
};

export default plugin;

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useMagicKeys, whenever } from '@vueuse/core';
import { ContainerSize, VButtonGroup, VSizeContainer } from '@shared/layout';
import { ButtonType, VButton } from '../VButton';
import VContentBox from '../VContentBox/VContentBox.vue';
import { ModalType } from '../types';

const props = withDefaults(
  defineProps<{
    isActive?: boolean;
    disabled?: boolean;
    type?: ModalType;
    confirmButtonText?: string;
    cancelButtonText?: string;
    denyButtonText?: string;
    loading?: boolean;
    overflow?: boolean;
    title?: string;
    invertHeader?: boolean;
    dataTestid?: string;
    size?: ContainerSize;
  }>(),
  {
    dataTestId: undefined,
    isActive: false,
    disabled: false,
    type: ModalType.Success,
    confirmButtonText: 'Opslaan',
    cancelButtonText: 'Annuleren',
    denyButtonText: '',
    overflow: false,
    invertHeader: false,
    size: 'deprecated_768'
  }
);

const emit = defineEmits<{
  closeModal: [value?: Event];
  confirmAction: [value?: Event];
  denyAction: [value?: Event];
  cancelButtonClicked: [value?: Event];
}>();

const scrollY = ref(0);

const keys = useMagicKeys();

whenever(keys.escape, () => emit('closeModal'));

watch(
  () => props.isActive,
  active => {
    scrollLock(active);
  }
);

const getButtonType = (): ButtonType => {
  switch (props.type) {
    case ModalType.Danger:
      return 'danger';
    case ModalType.Success:
    default:
      return 'primary';
  }
};

function scrollLock(lock: boolean) {
  if (lock) {
    scrollY.value = window.scrollY;
    document.body.classList.add('scroll-lock');
    document.body.style.top = `-${scrollY.value}px`;
  } else {
    document.body.classList.remove('scroll-lock');
    window.scrollTo(0, scrollY.value);
    scrollY.value = 0;
  }
}

function cancel() {
  emit('cancelButtonClicked');
  emit('closeModal');
}

onMounted(() => props.isActive && scrollLock(true));
onUnmounted(() => props.isActive && scrollLock(false));
</script>

<template>
  <Teleport to="body">
    <div
      class="tw-fixed tw-inset-0 tw-z-[999] tw-grid tw-min-w-[50%] tw-place-items-center"
      :class="{ 'tw-animate-[fade_0.3s_linear]': props.isActive, 'tw-hidden': !props.isActive }"
      :data-testid="props.dataTestid">
      <div class="tw-absolute tw-inset-0 tw-bg-grey-800/80" @click="emit('closeModal')"></div>
      <VSizeContainer :size="props.size">
        <VContentBox
          class="tw-grid tw-max-h-dvh tw-grid-rows-[auto_minmax(0,1fr)]"
          :margin-bottom="0"
          :title="props.title"
          :invert-header="invertHeader"
          :class="{
            'tw-overflow-visible': props.overflow,
            'tw-overflow-hidden': !props.overflow
          }">
          <div class="tw-grid tw-max-h-full tw-grid-rows-[minmax(0,1fr)_auto] tw-gap-4">
            <section
              class="tw-z-dialog -tw-m-4 -tw-mt-6 tw-mb-0 tw-p-4 tw-pb-0 tw-pt-6"
              :class="{
                'tw-overflow-visible': props.overflow,
                'tw-overflow-auto': !props.overflow
              }">
              <slot></slot>
            </section>

            <footer class="tw-flex tw-justify-end">
              <VButtonGroup>
                <VButton inverted v-if="props.cancelButtonText" @click.stop="cancel">{{
                  props.cancelButtonText
                }}</VButton>
                <VButton
                  :disabled="props.disabled"
                  v-if="props.denyButtonText"
                  @click="emit('denyAction')"
                  type="danger">
                  {{ props.denyButtonText }}
                </VButton>
                <VButton
                  v-if="props.confirmButtonText"
                  :disabled="props.disabled"
                  :type="getButtonType()"
                  :loading="props.loading"
                  @click.stop="emit('confirmAction')">
                  {{ props.confirmButtonText }}
                </VButton>
              </VButtonGroup>
            </footer>
          </div>
        </VContentBox>
      </VSizeContainer>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';
import Menu from 'primevue/menu';
import type { MenuProps } from 'primevue/menu';
import type { MenuItem } from 'primevue/menuitem';
import { computed, ref } from 'vue';
import { VMenuTheme, getPassthroughStyles, getStylesObj } from './VMenu.styles';

export type VMenuItem = Omit<MenuItem, 'icon'> & {
  badge?: string;
  icon?: FontAwesomeIconProps['icon'];
};

export type VMenuProps = {
  appendTo?: MenuProps['appendTo'];
  model?: VMenuItem[];
  popup?: MenuProps['popup'];
  pt?: MenuProps['pt'];
  ptOptions?: MenuProps['ptOptions'];
  theme?: VMenuTheme;
};

const props = defineProps<VMenuProps>();

const menu = ref();

const componentProps = computed(() => Object.fromEntries(Object.entries(props).filter(([key]) => key !== 'icon')));
const passthroughStyles = computed(() => getPassthroughStyles(props.theme, props.popup));

const stylesObj = computed(() => getStylesObj(props.theme));

defineExpose({
  hide: (event: Event) => menu.value.hide(event),
  show: (event: Event) => menu.value.show(event),
  toggle: (event: Event) => menu.value.toggle(event),
  overlayVisible: () => menu.value.overlayVisible
});
</script>

<template>
  <Menu
    v-bind="componentProps"
    ref="menu"
    :pt="passthroughStyles"
    :pt-options="{ mergeSections: true, mergeProps: true }"
    data-testid="menu">
    <template #itemicon="{ item }">
      <FontAwesomeIcon v-if="item.icon" class="tw-mr-2 [--fa-fw-width:1rem]" fixed-width :icon="item.icon" />
    </template>

    <template #start>
      <slot name="start"></slot>
    </template>

    <template #item="{ item, props }" v-if="$slots.item">
      <slot name="item" :item="item" :props="props" :styles="stylesObj"></slot>
    </template>
  </Menu>
</template>

export const multiselect = {
  root: ({ props, state }: Record<string, any>) => ({
    class: [
      // Display and Position
      'tw-inline-flex',
      'tw-relative',
      // Shape
      'tw-rounded-md',
      'tw-w-80 tw-max-w-full',
      // Color and Background
      'tw-text-grey-800',
      'tw-border tw-border-solid tw-border-grey-300',
      {
        'tw-bg-white': !props.disabled && !props.invalid,
        'tw-bg-grey-50': props.disabled,
        'tw-bg-red-100/50': props.invalid
      },
      // Invalid State
      { 'tw-border-red-500': props.invalid },
      // Transitions
      'tw-transition-all',
      'tw-duration-200',
      // States
      { 'hover:tw-border-surface-400': !props.invalid },
      { 'tw-outline-none tw-outline-offset-0 tw-z-10 tw-ring-1 tw-ring-primary-500': state.focused },
      // Misc
      'tw-cursor-pointer',
      'tw-select-none',
      { 'tw-bg-surface-200 tw-select-none tw-pointer-events-none tw-cursor-default': props.disabled }
    ]
  }),
  dropdown: ({ props }: Record<string, any>) => ({
    class: [
      { 'tw-hidden': props.disabled },
      'tw-flex tw-items-center tw-justify-center',
      'tw-shrink-0',
      'tw-bg-transparent',
      'tw-text-surface-500',
      'tw-w-12',
      'tw-rounded-r-md'
    ]
  }),
  labelcontainer: {
    class: ['tw-grow', 'tw-overflow-x-hidden']
  },
  label: ({ props }: Record<string, any>) => {
    return {
      class: [
        'tw-text-base tw-leading-[normal]',
        // Spacing
        {
          'tw-py-2 tw-px-4 tw-pr-0':
            props.display === 'comma' || (props.display === 'chip' && !!props?.modelValue?.length),
          'tw-py-1 tw-px-1': props.display === 'chip' && !!props?.modelValue?.length
        },
        // Color
        {
          'tw-text-surface-800': !!props?.modelValue?.length,
          'tw-text-surface-400': !!props?.modelValue?.length
        },
        'placeholder:tw-text-surface-400',
        // Transitions
        'tw-transition tw-duration-200',
        // Misc
        'tw-overflow-hidden tw-whitespace-nowrap tw-cursor-pointer tw-overflow-ellipsis'
      ]
    };
  },
  overlay: {
    class: [
      'tw-box-content',
      'tw-bg-white',
      'tw-border tw-border-solid tw-border-grey-200',
      'tw-rounded-lg',
      'tw-shadow'
    ]
  },
  listContainer: {
    class: ['tw-max-h-[200px]', 'tw-overflow-auto']
  },
  list: {
    class: 'tw-py-2 tw-list-none tw-m-0'
  },
  option: ({ context }: Record<string, any>) => ({
    class: [
      // Font
      'tw-leading-none',
      { 'tw-font-normal': !context.selected, 'tw-font-bold': context.selected },
      // Position
      'tw-flex tw-items-center',
      'tw-relative',
      // Shape
      'tw-border-0',
      'tw-rounded-none',
      // Spacing
      'tw-m-0',
      'tw-py-2 tw-px-4 tw-gap-2',
      // Color
      {
        'tw-bg-primary-200': context.focused && !context.selected
      },
      {
        'tw-bg-primary tw-text-white': context.focused && context.selected
      },
      { 'tw-bg-transparent': !context.focused && context.selected },
      // States
      'hover:tw-bg-primary hover:tw-text-white',
      // Misc
      'tw-cursor-pointer',
      'tw-overflow-hidden',
      'tw-whitespace-nowrap',
      'tw-group/option'
    ]
  }),
  pcOptionCheckbox: {
    box: {
      class: ['tw-hidden']
    }
  }
};

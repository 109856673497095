import { defineStore } from 'pinia';
import { computed, reactive, readonly } from 'vue';
import { useLocalStorage } from '@vueuse/core';

const timeoutInSeconds = 30 * 60;
const closeToTimeoutInSeconds = 20 * 60;

export const useTimeoutStore = defineStore('timeoutStore', () => {
  const state = reactive({
    lastRequest: useLocalStorage<number | null>('LAST_REQUEST', null),
    currentTime: Date.now()
  });

  function updateLastRequest() {
    state.lastRequest = new Date().getTime();
  }

  function isCloseToTimeout(): boolean {
    return checkTimeout(closeToTimeoutInSeconds);
  }

  function isTimedout(): boolean {
    return checkTimeout(timeoutInSeconds);
  }

  function checkTimeout(seconds: number): boolean {
    // set current time, because we can't use it in a computed
    state.currentTime = Date.now();
    if (!state.lastRequest) return false;
    const diff = (state.lastRequest - new Date().getTime()) * -1;
    return diff > seconds * 1000;
  }

  const secondsUntilTimeout = computed(() => {
    const ms = !state.lastRequest
      ? timeoutInSeconds
      : timeoutInSeconds * 1000 - (state.currentTime - state.lastRequest);
    return Math.round(ms / 1000);
  });

  return {
    state: readonly(state),
    updateLastRequest,
    isTimedout,
    isCloseToTimeout,
    lastRequest: computed(() => state.lastRequest),
    secondsUntilTimeout
  };
});

import { useQuery } from '@tanstack/vue-query';
import { environmentQueries } from '@services/overig/queries/EnvironmentQueries';
import { computed, ComputedRef } from 'vue';

type Toggle =
  | 'WatermelonChatToggle'
  | 'OmnisearchToggle'
  | 'ProductselectieV2'
  | 'InspectieAanvragenV2Toggle'
  | 'PremievaststellingsoverzichtV2Toggle'
  | 'SendRoyementsbevestigingToggle';

export function useFeatureToggle(toggle: Toggle): ComputedRef<boolean | undefined> {
  const env = useQuery({
    ...environmentQueries.get(),
    select: res => res.data.featureFlags,
    staleTime: 60 * 1000,
    retry: 10
  });
  return computed(() => env.data?.value?.[toggle]);
}

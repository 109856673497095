<script lang="ts" setup>
import { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';
import { FontAwesomeIcon, PrimeVuePt } from '@shared/plugin';
import Chip, { ChipProps } from 'primevue/chip';
import { computed } from 'vue';

export type VChipProps = {
  color?: keyof typeof colors;
  hideIcon?: keyof typeof hideIconBreakpoints;
  icon?: FontAwesomeIconProps['icon'];
  label?: ChipProps['label'];
  size?: keyof typeof sizes;
};

const props = withDefaults(defineProps<VChipProps>(), { size: 'base' });

const colors = {
  blue: 'tw-bg-blue',
  orange: 'tw-bg-orange',
  red: 'tw-bg-red',
  teal: 'tw-bg-teal'
} as const;

const hideIconBreakpoints = {
  sm: 'max-sm:tw-hidden',
  md: 'max-md:tw-hidden',
  lg: 'max-lg:tw-hidden',
  xl: 'max-xl:tw-hidden',
  '2xl': 'max-2xl:tw-hidden'
} as const;

const sizes = {
  sm: 'tw-text-[9px] tw-px-1.5 tw-leading-3 tw-py-0.5',
  base: 'tw-text-2xs tw-px-2 tw-leading-4 tw-py-1',
  lg: 'tw-text-[13px] tw-px-3.5 tw-leading-5 tw-py-1.5'
} as const;

const colorClasses = computed(() => (props.color ? colors[props.color] : 'tw-bg-grey-400'));
const componentProps = computed(() => Object.fromEntries(Object.entries(props).filter(([key]) => key !== 'icon')));
const hideIconClasses = computed(() => (props.hideIcon ? hideIconBreakpoints[props.hideIcon] : ''));
const sizeClasses = computed(() => sizes[props.size]);
</script>

<template>
  <Chip v-bind="componentProps" :class="[colorClasses, sizeClasses]">
    <template #icon>
      <FontAwesomeIcon
        v-if="props.icon"
        :class="{ [hideIconClasses]: true, [PrimeVuePt.chip.icon.class]: true }"
        :icon="props.icon" />
    </template>
  </Chip>
</template>

// @ts-expect-error
import tailwindConfig from '@tailwind-config';
import resolveConfig from 'tailwindcss/resolveConfig';
import { computed } from 'vue';
import { useElementSize, type MaybeComputedElementRef } from '@vueuse/core';

const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
const { theme } = resolveConfig(tailwindConfig);

export function useContainerQuery(containerElement: MaybeComputedElementRef) {
  const { width } = useElementSize(containerElement);

  const getBreakpointQuery = (tailwindContainerValue: string) =>
    computed(() => width.value >= parseInt(tailwindContainerValue, 10) * remInPx);

  return {
    minXs: getBreakpointQuery(theme.containers.xs),
    minSm: getBreakpointQuery(theme.containers.sm),
    minMd: getBreakpointQuery(theme.containers.md),
    minLg: getBreakpointQuery(theme.containers.lg),
    minXl: getBreakpointQuery(theme.containers.xl),
    min2xl: getBreakpointQuery(theme.containers['2xl']),
    min3xl: getBreakpointQuery(theme.containers['3xl']),
    min4xl: getBreakpointQuery(theme.containers['4xl']),
    min5xl: getBreakpointQuery(theme.containers['5xl']),
    min6xl: getBreakpointQuery(theme.containers['6xl']),
    min7xl: getBreakpointQuery(theme.containers['7xl'])
  };
}

import { createQueryKeys } from '@lukemorales/query-key-factory';
import apiService from '@services/base/apiService';

export const menuQueries = createQueryKeys('menu', {
  getMenuItems: {
    queryKey: null,
    queryFn: () => apiService.apiClient.get(`/content/ajax/getMenuItems/`)
  },
  getCrvp: {
    queryKey: null,
    queryFn: () => apiService.apiClient.get<GetPortefeuilleDashboard>(`/content/ajax/GetPortefeuilleDashboard/`)
  },
  getTellingen: {
    queryKey: null,
    queryFn: () => apiService.apiClient.get('/content/ajax/getMenuItems/getTellingen.asp')
  },
  getAantalPremievastellingen: {
    queryKey: null,
    queryFn: () => apiService.apiClient.get<number>('/net/api/Naverreken/GetCount')
  }
});

interface GetPortefeuilleDashboard {
  CRVPlopendjaar: string;
  CRVPlopendjaar_round: string;
  gelukt: boolean;
  jaar: string;
  kleur: string;
}

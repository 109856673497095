// @ts-nocheck
/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * SUREnet crm-public API
 * OpenAPI spec version: 1
 */

export type SUREbusinessCommonDomainAdviseurStatus =
  (typeof SUREbusinessCommonDomainAdviseurStatus)[keyof typeof SUREbusinessCommonDomainAdviseurStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SUREbusinessCommonDomainAdviseurStatus = {
  Actief: 'Actief',
  Beeindigd: 'Beeindigd',
  Geblokkeerd: 'Geblokkeerd',
  Kandidaat: 'Kandidaat',
  Onbekend: 'Onbekend'
} as const;

<script lang="ts" setup>
import { FontAwesomeIcon } from '@shared/plugin';
import { computed } from 'vue';

import VChip from '../VChip/VChip.vue';
import VStyledHtml from '../VStyledHtml/VStyledHtml.vue';
import { InfoBoxType } from './types';

const props = defineProps<{
  type?: InfoBoxType;
  badgeText?: string;
}>();

const wrapperClasses = computed(() => {
  switch (props.type) {
    case 'info':
      return 'tw-border-info-300 tw-bg-info-200';
    case 'warning':
      return 'tw-border-orange-300 tw-bg-orange-200';
    case 'danger':
      return 'tw-border-red-100 tw-bg-red-50';
    default:
      return 'tw-border-grey-200 tw-bg-white';
  }
});

const tagColor = computed(() => {
  switch (props.type) {
    case 'info':
      return 'blue';
    case 'warning':
      return 'orange';
    case 'danger':
      return 'red';
    default:
      return undefined;
  }
});

const icon = computed(() => {
  switch (props.type) {
    case 'danger':
    case 'warning':
      return 'far fa-info-circle';
    case 'info':
    default:
      return 'far fa-info-circle';
  }
});
</script>

<template>
  <div
    class="tw-flex tw-rounded tw-border tw-border-solid tw-px-4 tw-py-6 md:tw-p-8 md:tw-pt-7"
    :class="{
      [wrapperClasses]: true,
      'tw-gap-2': !props.badgeText,
      'tw-flex-col tw-gap-4': props.badgeText
    }">
    <VChip v-if="props.badgeText" class="tw-self-start" :color="tagColor" :icon :label="props.badgeText" />

    <FontAwesomeIcon v-else class="tw-pt-1" :icon />

    <VStyledHtml class="tw-w-full tw-text-sm tw-text-grey-600">
      <slot></slot>
    </VStyledHtml>
  </div>
</template>

export const inputmask = {
  pcInputText: ({ props }: Record<string, any>) => ({
    root: {
      class: [
        // Font
        'tw-leading-none',
        // Spacing
        'tw-m-0',
        {
          'tw-p-0': props.readonly,
          'tw-py-2.5 tw-px-4': !props.readonly,
          'tw-flex-1': !props.readonly
        },
        // Colors
        {
          'placeholder:tw-text-grey-400': !props.readonly,
          'placeholder:tw-text-transparent': props.readonly,
          'tw-text-grey-900': !props.disabled,
          'tw-text-grey-500': !!props.disabled,
          'tw-cursor-not-allowed': !!props.disabled,
          'tw-border': !props.readonly,
          'tw-border-grey-300': !props.invalid
        },
        // Invalid State
        'invalid:focus:tw-ring-red-200',
        'invalid:hover:tw-border-red-500',
        { 'tw-border-[#d4d4d4] tw-bg-[#fce8ec]': props.invalid },
        // States
        'tw-outline-none',
        {
          'tw-bg-grey-50': props.disabled,
          'hover:tw-border-grey-400': !props.readonly && !props.invalid,
          'tw-select-none tw-pointer-events-none tw-cursor-default': props.readonly
        },
        // Misc
        'tw-rounded-lg',
        'tw-appearance-none',
        'tw-transition-colors tw-duration-200',
        'tw-flex-auto'
      ]
    }
  })
};

<script lang="ts" setup>
import { useClaims } from '@auth';
import { environmentName, showBanner } from '@environment-lib';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { menuQueries } from '@services/asp-c/queries/Menu';
import { VBreadcrumb, VHeading, VSidePanel } from '@shared/component';
import { useQuery } from '@tanstack/vue-query';
import { navigateToUrlWithPostRequest } from '@tools-lib';
import { useElementBounding } from '@vueuse/core';
import { computed, reactive, ref, watch } from 'vue';

import { useBreadcrumbStore } from '../../breadcrumb/breadcrumbStore';
import AppLayoutContact from './AppLayoutContact.vue';
import AppLayoutCrvp from './AppLayoutCrvp.vue';
import AppLayoutLogo from './AppLayoutLogo/AppLayoutLogo.vue';
import AppLayoutMenuAccount from './AppLayoutMenuAccount.vue';
import AppLayoutMenuContact from './AppLayoutMenuContact.vue';
import AppLayoutMenuIntern from './AppLayoutMenuIntern.vue';
import AppLayoutMenuMain from './AppLayoutMenuMain.vue';
import AppLayoutSearch from './AppLayoutSearch.vue';
import GebruikerZoeken from '../gebruikerzoeken/GebruikerZoeken.vue';
import { MenuItem } from './types';

const props = defineProps<{
  customMenuItems?: Array<MenuItem>;
  customMenuLoadingState?: boolean;
  navigateInFrame?: boolean;
}>();

const state = reactive({
  mainMenuOpen: false,
  searchUserOpen: false
});

const menuContainer = ref(null);

const breadcrumbStore = useBreadcrumbStore();
const claims = useClaims();
const { width: menuContainerWidth } = useElementBounding(menuContainer);
const { data: ratio } = useQuery({
  ...menuQueries.getCrvp,
  select: result => result.data,
  enabled: computed(() => claims.value?.isSuccess === true)
});

const closeSearchUser = () => (state.searchUserOpen = false);

watch(
  () => claims.value?.isWebmodule,
  isWebmodule => {
    const { style } = document.documentElement;
    const variableName = '--header-height';

    if (isWebmodule) {
      style.setProperty(variableName, '0');
    } else {
      style.removeProperty(variableName);
    }
  },
  { immediate: true }
);

function logout() {
  navigateToUrlWithPostRequest(
    `/identity/authentication/logout?returnUrl=${encodeURIComponent('/identity/user/verlaten')}`
  );
}
</script>

<template>
  <div
    class="tw-grow tw-text-primary-900"
    :class="{
      'tw-grid tw-grid-cols-[auto_1fr] tw-grid-rows-[var(--header-height,0px)_1fr] max-lg:before:tw-absolute max-lg:before:tw-h-16 max-lg:before:tw-w-full max-lg:before:tw-shadow-sm max-lg:before:tw-content-[\'\']':
        !claims?.isWebmodule
    }"
    :style="{
      '--menu-container-width': `${menuContainerWidth}px`
    }">
    <template v-if="!claims?.isWebmodule">
      <div
        :class="[
          'tw-sticky tw-top-0 tw-z-header tw-flex tw-border-0 tw-border-b tw-border-solid tw-border-grey-200 tw-bg-white tw-px-2 lg:tw-border-r lg:tw-px-4',
          {
            'tw-max-w-64': claims?.isTemporaryUser,
            'tw-border-b-2': showBanner,
            'tw-border-b-orange': environmentName === 'local' && showBanner,
            'tw-border-b-red': environmentName === 'test' && showBanner,
            'tw-border-b-blue': environmentName === 'acceptatie' && showBanner
          }
        ]">
        <button
          v-if="!claims?.isTemporaryUser"
          class="tw-cursor-pointer tw-appearance-none tw-border-none tw-bg-transparent tw-px-2 tw-text-lg tw-text-inherit tw-outline-none"
          type="button"
          @click="state.mainMenuOpen = !state.mainMenuOpen"
          data-testid="toggle-menu">
          <FontAwesomeIcon :icon="['fas', 'bars']" />
        </button>

        <AppLayoutLogo class="tw-min-w-0 tw-px-2" />
      </div>

      <header
        :class="[
          'tw-sticky tw-top-0 tw-z-header tw-flex tw-justify-end tw-border-0 tw-border-b tw-border-solid tw-border-grey-200 tw-bg-white tw-pl-2 lg:tw-pl-6',
          {
            'tw-border-b-2': showBanner,
            'tw-border-b-orange tw-bg-env-local': environmentName === 'local' && showBanner,
            'tw-border-b-red tw-bg-env-test': environmentName === 'test' && showBanner,
            'tw-border-b-blue tw-bg-env-acceptatie': environmentName === 'acceptatie' && showBanner
          }
        ]">
        <VBreadcrumb v-if="!claims?.isTemporaryUser" class="tw-grow" :items="breadcrumbStore.breadcrumbs" />

        <nav class="tw-flex tw-px-2 lg:tw-divide-x lg:tw-divide-y-0 lg:tw-divide-solid lg:tw-divide-grey-200">
          <template v-if="!claims?.isTemporaryUser">
            <AppLayoutContact class="tw-hidden tw-px-6 lg:tw-flex" />

            <AppLayoutCrvp
              v-if="ratio"
              class="max-xl:tw-hidden lg:tw-px-6"
              :color-name="ratio.kleur"
              :rounded-value="ratio.CRVPlopendjaar_round" />

            <AppLayoutSearch />

            <AppLayoutMenuIntern v-if="claims?.isInternal" v-model:searchUserOpen="state.searchUserOpen" />

            <AppLayoutMenuAccount />
          </template>

          <template v-else>
            <AppLayoutMenuContact />

            <a
              class="tw-flex tw-items-center tw-gap-1 tw-px-3 tw-text-sm tw-text-inherit tw-no-underline lg:tw-px-6"
              :href="`/identity/authentication/logout?returnUrl=${encodeURIComponent('/identity/user/verlaten')}`"
              @click.prevent="logout">
              <FontAwesomeIcon :icon="['far', 'right-from-bracket']" />
              <span class="max-sm:tw-hidden">Uitloggen</span>
            </a>
          </template>
        </nav>
      </header>
    </template>

    <div
      v-if="!claims?.isTemporaryUser"
      :class="[
        'max-3xl:tw-absolute 3xl:tw-transition-[clip-path] 3xl:tw-duration-300',
        {
          '3xl:tw-clip-path-[rect(0_0_100%_0)]': !state.mainMenuOpen,
          '3xl:tw-clip-path-[rect(0_100%_100%_0)]': state.mainMenuOpen
        }
      ]"
      ref="menuContainer">
      <AppLayoutMenuMain
        v-model:open="state.mainMenuOpen"
        v-model:searchUserOpen="state.searchUserOpen"
        :custom-items="props.customMenuItems"
        :custom-loading-state="props.customMenuLoadingState"
        :navigate-in-frame="props.navigateInFrame" />
    </div>

    <div
      :class="[
        'tw-flex tw-flex-col',
        {
          'max-3xl:tw-col-span-2 3xl:tw-transition-[margin] 3xl:tw-duration-300': !claims?.isTemporaryUser,
          'tw-col-span-2': claims?.isTemporaryUser,
          '3xl:-tw-ml-[var(--menu-container-width,0)]': !claims?.isTemporaryUser && !state.mainMenuOpen
        }
      ]">
      <slot></slot>
    </div>

    <VSidePanel :open="state.searchUserOpen" @close="closeSearchUser()">
      <template #header>
        <VHeading class="tw-text-white" :level="3">Zoek een gebruiker</VHeading>
      </template>

      <template #default>
        <GebruikerZoeken v-if="state.searchUserOpen" @close="closeSearchUser()" />
      </template>
    </VSidePanel>
  </div>
</template>

import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { NotificationParamsWithTimestamp } from './types';

export const useNotificationStore = defineStore({
  id: 'notificationStore',
  state: () => ({
    notifications: useStorage('NOTIFICATION_LOG_KEY', [] as NotificationParamsWithTimestamp[])
  }),
  getters: {
    notificationsChronologically: (state): NotificationParamsWithTimestamp[] =>
      state.notifications.sort(
        (a: NotificationParamsWithTimestamp, b: NotificationParamsWithTimestamp) =>
          Number(new Date(b.timestamp ?? 0)) - Number(new Date(a.timestamp ?? 0))
      )
  }
});

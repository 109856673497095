import { RouteLocationNormalized, RouteParamValue, Router } from 'vue-router';
import { Util } from '@microsoft/applicationinsights-web';
import { unref } from 'vue';

import { appInsights, applicationInsights } from './applicationInsights';
import Tracking from './trackingConfiguration';

type Properties = { [key: string]: string };

async function setupPageTracking(applicationName: string, router: Router) {
  const baseName = `${applicationName || '(Vue App)'}`;
  const name = (route: RouteLocationNormalized) => `${baseName} / ${String(route.meta.title ?? route.name)}`;

  router.beforeEach((route, from, next) => {
    try {
      const operationName = route.matched[route.matched.length - 1]?.path ?? route.fullPath;

      applicationInsights.context.telemetryTrace.traceID = Util.generateW3CId();
      applicationInsights.context.telemetryTrace.name = `[${applicationName}] ${operationName}`;

      appInsights.startTrackPage(name(route));
    } catch (err) {
      /* eslint-disable no-console  */
      console.error(err);
      /* eslint-enable no-console */
    }
    next();
  });

  router.afterEach(route => {
    const url = `${window.location.href}`;
    const convertedParams = convertRouteParams(route.params);
    try {
      appInsights.stopTrackPage(name(route), url, { ...convertedParams, refUri: url });
    } catch (err) {
      /* eslint-disable no-console  */
      console.error(err);
      /* eslint-enable no-console */
    }
  });
}

function trackEvent(eventName: keyof typeof Tracking, eventProps: any) {
  if (Tracking[eventName].isEnabled) {
    try {
      appInsights.trackEvent({ name: Tracking[eventName]?.name, properties: unref(eventProps) });
    } catch (err) {
      /* eslint-disable no-console  */
      console.error(err);
      /* eslint-enable no-console */
    }
  }
}

function trackEventMethod(payload: any, trackingConfig: { [key: string]: { name: string; isEnabled: boolean } }) {
  const data = { ...payload };
  if ('value' in data) {
    data.value = (typeof data.value !== 'object' ? data.value : JSON.stringify(data.value)) || '';
  }
  delete data.name;
  if (trackingConfig[payload?.name].isEnabled) {
    try {
      appInsights.trackEvent({ name: trackingConfig[payload?.name].name }, data);
    } catch (err) {
      /* eslint-disable no-console  */
      console.error(err);
      /* eslint-enable no-console */
    }
  }
}

function trackException(error: any) {
  try {
    appInsights.trackException(error);
  } catch (err) {
    // left blank intentionally. This will cause an infinite loop and cause browser to crash if we throw exception here
  }
}

function startTrackPage(pageName: string) {
  try {
    appInsights.startTrackPage(pageName);
  } catch (err) {
    /* eslint-disable no-console  */
    console.error(err);
    /* eslint-enable no-console */
  }
}

function stopTrackPage(pageName: string) {
  try {
    appInsights.stopTrackPage(pageName);
  } catch (err) {
    /* eslint-disable no-console  */
    console.error(err);
    /* eslint-enable no-console */
  }
}

function startTrackEvent(eventName: string) {
  try {
    appInsights.startTrackEvent(eventName);
  } catch (err) {
    /* eslint-disable no-console  */
    console.error(err);
    /* eslint-enable no-console */
  }
}

function stopTrackEvent(event: any) {
  try {
    const data = { ...event };
    const attrs = ['req', 'res', 'config'];
    attrs.forEach(element => {
      if (element in data) {
        data[element] = (typeof data[element] !== 'object' ? data.request : JSON.stringify(data[element])) || '';
      }
    });
    delete data.name;
    appInsights.stopTrackEvent(event.name, data);
  } catch (err) {
    /* eslint-disable no-console  */
    console.error(err);
    /* eslint-enable no-console */
  }
}

export interface UserContextParams {
  accountId: string;
  storeInCookie: boolean;
  authenticatedUserId: string;
}

function setUserContextMethod(payload: UserContextParams) {
  const { accountId = '0', storeInCookie = false } = payload;
  let { authenticatedUserId } = payload;
  authenticatedUserId = authenticatedUserId.replace(/[,;=| ]+/g, '_');

  applicationInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
}

function clearUserContextMethod() {
  applicationInsights.clearAuthenticatedUserContext();
}

function dependencyMethod(payload: any) {
  const { status: resStatus } = payload.request;
  let { response: resBody = '' } = payload.request;
  const { url: reqUrl, method: reqMethod, data: reqBody = '' } = payload.config;
  const { CKey: cKey } = payload.config.headers;
  const webResBodyString = 'doctype html';
  if (resBody && resBody?.toLowerCase()?.includes(webResBodyString)) {
    resBody = null;
  } else {
    resBody = JSON.stringify(resBody);
  }
  const id = `M-${Math.random().toString(36).slice(-6)}`;
  const data = JSON.stringify({ reqBody, resBody, cKey });

  applicationInsights.trackDependencyData({
    id,
    responseCode: resStatus,
    name: `${reqMethod.toUpperCase()} ${reqUrl}`,
    success: false,

    data
  });
}

function convertRouteParams(params: { [p: string]: string | RouteParamValue[] }): Properties {
  const convertedParams: Properties = {};

  Object.keys(params).forEach(key => {
    convertedParams[key] = params[key].toString();
  });

  return convertedParams;
}

export {
  setupPageTracking,
  trackEventMethod,
  trackEvent,
  trackException,
  startTrackPage,
  stopTrackPage,
  startTrackEvent,
  stopTrackEvent,
  setUserContextMethod,
  clearUserContextMethod,
  dependencyMethod
};

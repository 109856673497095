export interface LookupResult<TValue, TOption, TEmit = undefined> {
  value: TValue;
  option: TOption;
  emits?: TEmit;
}

export enum Views {
  Default = 'Default',
  Basic = 'Basic',
  Extended = 'Extended'
}

export type LookupFn = (value: string) => Promise<LookupResult<boolean | number | string, OptionBasic>[]>;

export interface OptionBasic {
  item: string;
  display: string;
  subitems: string[];
}

// @ts-nocheck
/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * SUREnet crm-internal API
 * OpenAPI spec version: 1
 */
import { useQuery } from '@tanstack/vue-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import type { MaybeRef } from 'vue';
import type {
  CollectionResultOfCrmGebruikersGetGebruikersLikeStringDto,
  GebruikerSearchParams,
  Result1OfCrmGebruikersGebruikerDto
} from '../model';
import { customInstance } from '../../customInstance';
import type { ErrorType } from '../../customInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.GebruikerInzien</li></ul>
 * @summary Zoek naar gebruikers
 */
export const gebruikerSearch = (
  params?: MaybeRef<GebruikerSearchParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  params = unref(params);

  return customInstance<CollectionResultOfCrmGebruikersGetGebruikersLikeStringDto>(
    { url: `/api/v1/gebruikers/search`, method: 'GET', params: unref(params), signal },
    options
  );
};

export const getGebruikerSearchQueryKey = (params?: MaybeRef<GebruikerSearchParams>) => {
  return ['api', 'v1', 'gebruikers', 'search', ...(params ? [params] : [])] as const;
};

export const getGebruikerSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof gebruikerSearch>>,
  TError = ErrorType<void>
>(
  params?: MaybeRef<GebruikerSearchParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gebruikerSearch>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGebruikerSearchQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gebruikerSearch>>> = ({ signal }) =>
    gebruikerSearch(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gebruikerSearch>>,
    TError,
    TData
  >;
};

export type GebruikerSearchQueryResult = NonNullable<Awaited<ReturnType<typeof gebruikerSearch>>>;
export type GebruikerSearchQueryError = ErrorType<void>;

/**
 * @summary Zoek naar gebruikers
 */
export const useGebruikerSearch = <TData = Awaited<ReturnType<typeof gebruikerSearch>>, TError = ErrorType<void>>(
  params?: MaybeRef<GebruikerSearchParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gebruikerSearch>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGebruikerSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.GebruikerInzien</li></ul>
 * @summary Gebruiker ophalen
 */
export const gebruikerGet = (
  gebruikerId: MaybeRef<number | undefined | null>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  gebruikerId = unref(gebruikerId);

  return customInstance<Result1OfCrmGebruikersGebruikerDto>(
    { url: `/api/v1/gebruikers/${gebruikerId}`, method: 'GET', signal },
    options
  );
};

export const getGebruikerGetQueryKey = (gebruikerId: MaybeRef<number | undefined | null>) => {
  return ['api', 'v1', 'gebruikers', gebruikerId] as const;
};

export const getGebruikerGetQueryOptions = <TData = Awaited<ReturnType<typeof gebruikerGet>>, TError = ErrorType<void>>(
  gebruikerId: MaybeRef<number | undefined | null>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gebruikerGet>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGebruikerGetQueryKey(gebruikerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gebruikerGet>>> = ({ signal }) =>
    gebruikerGet(gebruikerId, requestOptions, signal);

  return { queryKey, queryFn, enabled: computed(() => !!unref(gebruikerId)), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gebruikerGet>>,
    TError,
    TData
  >;
};

export type GebruikerGetQueryResult = NonNullable<Awaited<ReturnType<typeof gebruikerGet>>>;
export type GebruikerGetQueryError = ErrorType<void>;

/**
 * @summary Gebruiker ophalen
 */
export const useGebruikerGet = <TData = Awaited<ReturnType<typeof gebruikerGet>>, TError = ErrorType<void>>(
  gebruikerId: MaybeRef<number | undefined | null>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gebruikerGet>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGebruikerGetQueryOptions(gebruikerId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

import addVariantEm from './src/plugins/addVariantEm';
const plugin = require('tailwindcss/plugin');

/** @type {import('tailwindcss').Config} */
module.exports = {
  prefix: 'tw-',
  important: true,
  corePlugins: {
    preflight: true
  },
  theme: {
    colors: {
      black: '#021F1D',
      blue: '#2369F9',
      current: 'currentColor',
      green: '#31DC72',
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#e5e5e5',
        300: '#d4d4d4',
        400: '#a3a3a3',
        DEFAULT: '#737373',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
        950: '#0a0a0a'
      },
      info: {
        200: 'var(--info-xlight)',
        300: 'var(--info-light)',
        DEFAULT: 'var(--info)',
        500: 'var(--info)'
      },
      inherit: 'inherit',
      orange: {
        200: '#FBF4EB',
        300: '#F2DCBF',
        400: '#FF9900',
        DEFAULT: '#F29322',
        500: '#F29322',
        700: '#CF7D1D'
      },
      primary: {
        200: 'rgb(var(--primary-lighter-rgb) / <alpha-value>)',
        300: 'var(--primary-light)',
        DEFAULT: 'var(--primary)',
        500: 'var(--primary)',
        600: 'var(--primary-dark)',
        700: 'var(--primary-darker)',
        800: '#223D3B',
        900: 'rgb(var(--primary-900-rgb) / <alpha-value>)'
      },
      purple: '#8A3FFC',
      red: {
        50: '#fce8ed',
        100: '#f9d1db',
        200: '#f4a3b7',
        300: '#ee7692',
        400: '#e9486e',
        DEFAULT: '#e31a4a',
        500: '#e31a4a',
        600: '#b6153b',
        700: '#88102c',
        800: '#5b0a1e',
        900: '#2d050f',
        950: '#170307'
      },
      secondary: {
        300: 'var(--secondary-light)',
        DEFAULT: 'var(--secondary)',
        500: 'var(--secondary)',
        600: 'var(--secondary-dark)'
      },
      success: 'rgb(var(--success-rgb) / <alpha-value>)',
      teal: {
        200: '#46E4DC',
        DEFAULT: '#0EA199'
      },
      transparent: 'transparent',
      'grey-accent': {
        100: '#fbfaf5',
        200: '#beb1a8'
      },
      white: '#ffffff',
      yellow: '#F4CA64'
    },
    fontSize: {
      '3xs': '0.5rem', // 8px
      '2xs': '0.6875rem', // 11px
      xs: '0.75rem', //12px
      sm: '0.875rem', //14px
      base: '1rem', //16px
      lg: '1.125rem', //18px
      xl: '1.375rem', //22px
      '2xl': '1.75rem', //28px
      '3xl': '2rem' //32px
    },
    extend: {
      backgroundImage: {
        'env-local': "url('@assets/images/env-bg-local.svg')",
        'env-test': "url('@assets/images/env-bg-test.svg')",
        'env-acceptatie': "url('@assets/images/env-bg-acceptatie.svg')"
      },
      boxShadow: {
        sm: '0 3px 5px rgb(0 0 0 / .05)',
        top: '0 -0.9375rem 0.9375rem -1.125rem rgb(0 0 0 / 0.1)'
      },
      gap: {
        ch: '1ch'
      },
      minHeight: {
        'form-row': 'var(--min-height-form-row)'
      },
      screens: {
        '3xl': '1912px'
      },
      transitionDuration: {
        400: '.4s'
      },
      zIndex: {
        //Voor popups e.d. die altijd over de rest gerenderd moeten worden
        overlay: 250,
        //Voor widgets (kalenders e.d.) die over alle pagina-content (inclusief headers) gerenderd moeten worden maar onder overlay elementen zoals popups en modals
        widget: 220,
        //Headers die over de content heen gerenderd moeten worden
        header: 200,
        //Subheaders die over de content heen, maar onder primaire headers gerenderd moeten worden.
        subheader: 150,
        //dialog boxes die boven de overige content moeten vallen
        dialog: 100
      }
    },
    fontFamily: {
      sans: ['Open Sans', 'sans-serif'],
      mono: ['Fira Mono', 'Courier New']
    },
    fontWeight: {
      normal: '400',
      semibold: '600',
      bold: '700',
      extrabold: '800'
    }
  },
  plugins: [
    require('@tailwindcss/container-queries'),
    addVariantEm,
    plugin(({ addUtilities, addVariant, matchUtilities }) => {
      addUtilities({
        '.clip-path-checkmark': {
          clipPath: 'polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)'
        },
        '.display-unset': {
          display: 'unset'
        },
        '.overflow-wrap-anywhere': {
          overflowWrap: 'anywhere'
        },
        '.scrollbar-light': {
          scrollbarColor: 'rgb(255 255 255 / 0.25) transparent'
        },
        '.scrollbar-thin': {
          scrollbarWidth: 'thin'
        }
      });

      addVariant('not-first', '&:not(:first-child)');
      addVariant('not-last', '&:not(:last-child)');

      matchUtilities({
        'clip-path': value => ({
          'clip-path': value
        })
      });
    })
  ]
};

<script lang="ts" setup>
import Avatar from 'primevue/avatar';
import { computed, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    name?: string | null;
    imageUrl?: string;
    size?: 'normal' | 'large' | 'xlarge' | undefined;
    rounded?: boolean;
  }>(),
  { rounded: false, size: undefined, imageUrl: undefined, name: undefined }
);

const logoExists = ref(false);

const label = computed(() => {
  if (!props.name || props.name?.trim() === '') return undefined;

  const words = props.name?.trim().split(/[\s-]+/);

  if (words.length === 1) return words[0].substring(0, 2).toUpperCase();

  const letters = words.map(word => word.substring(0, 1));

  return [letters[0], letters[letters.length - 1]].join('').toUpperCase();
});

onMounted(() => props.imageUrl && fetch(props.imageUrl).then(res => (logoExists.value = res.ok)));
</script>

<template>
  <Avatar
    :image="logoExists ? imageUrl : undefined"
    :size
    :label="logoExists ? undefined : label"
    :shape="rounded ? 'circle' : undefined" />
</template>

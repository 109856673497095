<script lang="ts" setup>
import { ParsedBreadcrumb } from './types';
import VBreadcrumbItem from './VBreadcrumbItem.vue';

const props = withDefaults(
  defineProps<{
    items?: ParsedBreadcrumb[];
  }>(),
  {
    items: () => []
  }
);
</script>

<template>
  <nav class="tw-flex tw-@container/breadcrumb" aria-label="breadcrumbs">
    <ul class="tw-flex tw-overflow-hidden">
      <VBreadcrumbItem link="/content/">Dashboard</VBreadcrumbItem>

      <VBreadcrumbItem v-for="{ link, name, target } in props.items" :key="name" :link="link" :target="target">
        {{ name }}
      </VBreadcrumbItem>
    </ul>
  </nav>
</template>

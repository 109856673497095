<script lang="ts" setup>
import { brandingQueries } from '@services/overig/queries/BrandingQueries';
import { VSkeleton } from '@shared/component';
import { useQuery } from '@tanstack/vue-query';
import { reactive } from 'vue';

const state = reactive<{
  imageState: 'error' | 'loaded' | 'loading';
}>({
  imageState: 'loading'
});

const { data: brandingData } = useQuery({
  ...brandingQueries.fetchBranding(),
  select: result => result.data.result
});
</script>

<template>
  <template v-if="brandingData">
    <img
      v-if="state.imageState !== 'error'"
      :alt="brandingData.kantoornaam"
      class="tw-max-h-full"
      :class="{ 'tw-invisible': state.imageState === 'loading' }"
      :src="`/tpimages/h150/${brandingData.logo}`"
      @error="state.imageState = 'error'"
      @load="state.imageState = 'loaded'" />

    <span v-else class="tw-block tw-min-w-0 tw-truncate tw-text-center tw-font-bold tw-text-primary">
      {{ brandingData.kantoornaam }}
    </span>
  </template>

  <VSkeleton v-else height="lg" />
</template>

import { userApi } from '@services/identity-api/identityService';
import { IdentityWebModelsUserUserClaimDto as Claim } from '@services/identity-api/models';
import { navigateToUrlWithPostRequest } from '@tools-lib';
import { ref } from 'vue';
import { FlattenedPermissions } from './types';

const userClaimStore = ref<Claim[]>();

const updateClaims = async () => {
  const result = await userApi.apiV1UserClaimsGet();
  const claimDtos = result.data;
  userClaimStore.value = claimDtos;
  return claimDtos;
};

const getOrUpdateClaims = async (): Promise<Array<Claim>> => {
  if (!userClaimStore.value) return updateClaims();
  return userClaimStore.value;
};
const containsPermission = (claims: Array<Claim>, permission: string) => {
  const authorization = claims.filter(a => a.value === permission && a.type === 'Permission');
  return authorization.length > 0;
};

const hasPermission = async (permission: FlattenedPermissions): Promise<boolean> => {
  const claims = await getOrUpdateClaims();
  return containsPermission(claims, permission);
};

function handleUnauthorized(sessieVerlaten?: boolean) {
  const loginUrl = `https://${window.location.host}/Identity/Authentication/Login?returnUrl=`;
  const returnUrl = sessieVerlaten ? '/identity/user/verlaten' : `${window.location.pathname}${window.location.search}`;
  window.location.href = `${loginUrl}${encodeURIComponent(returnUrl)}&expired=true`;
}

function signOut(sessieVerlaten?: boolean) {
  const returnUrl = sessieVerlaten ? '/identity/user/verlaten' : '';

  navigateToUrlWithPostRequest(`/Identity/Authentication/Logout?returnUrl=${returnUrl}`);
}

async function getClaims() {
  const claims = await getOrUpdateClaims();
  return {
    isWebmodule: claims.some(d => d.type === 'WebmoduleId' && d.value !== '0') ?? false,
    isTemporaryUser: claims.some(d => d.type === 'IsTemporaryUser' && d.value === 'True') ?? false,
    isInternal: claims.some(d => d.type === 'IsInternal' && d.value === 'True') ?? false,
    webmoduleId: claims.some(d => d.type === 'WebmoduleId')
      ? Number(claims.filter(d => d.type === 'WebmoduleId')[0].value)
      : undefined
  } as const;
}

const type = () => 'API Authorization';

export default {
  getClaims,
  hasPermission,
  type,
  signOut,
  handleUnauthorized
};

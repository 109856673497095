import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as duotone from './fontAwesomeDuotone';
import * as light from './fontAwesomeLight';
import * as regular from './fontAwesomeRegular';
import * as solid from './fontAwesomeSolid';

library.add(duotone, light, regular, solid);

export { FontAwesomeIcon };

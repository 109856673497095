import { ComputedRef, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { userQueries } from '@services/identity-api/queries/User';
import { IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto } from '@services/identity-api/models';

export function useProfile(): ComputedRef<IdentityApplicationFeaturesUsersGetUserProfileUserProfileDto | undefined> {
  const profile = useQuery({
    ...userQueries.getProfile,
    staleTime: 60 * 1000,
    retry: 10
  });
  return computed(() => profile.data?.value?.data);
}

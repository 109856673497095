// @ts-nocheck
/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * SUREnet overig API
 * OpenAPI spec version: 1
 */
import { useQuery } from '@tanstack/vue-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { unref } from 'vue';
import type { MaybeRef } from 'vue';
import type { CollectionResultOfSUREbusinessManagementSearchSearchDto, SearchGetParams } from '../model';
import { customInstance } from '../../customInstance';
import type { ErrorType } from '../../customInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
 */
export const searchGet = (
  params?: MaybeRef<SearchGetParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  params = unref(params);

  return customInstance<CollectionResultOfSUREbusinessManagementSearchSearchDto>(
    { url: `/api/v1/search`, method: 'GET', params: unref(params), signal },
    options
  );
};

export const getSearchGetQueryKey = (params?: MaybeRef<SearchGetParams>) => {
  return ['api', 'v1', 'search', ...(params ? [params] : [])] as const;
};

export const getSearchGetQueryOptions = <TData = Awaited<ReturnType<typeof searchGet>>, TError = ErrorType<unknown>>(
  params?: MaybeRef<SearchGetParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGet>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getSearchGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchGet>>> = ({ signal }) =>
    searchGet(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchGet>>,
    TError,
    TData
  >;
};

export type SearchGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchGet>>>;
export type SearchGetQueryError = ErrorType<unknown>;

/**
 * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
 */
export const useSearchGet = <TData = Awaited<ReturnType<typeof searchGet>>, TError = ErrorType<unknown>>(
  params?: MaybeRef<SearchGetParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGet>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

<script lang="ts">
import { computed } from 'vue';

export type ContainerSize = 'sm' | 'md' | 'deprecated_768' | 'deprecated_1200' | 'deprecated_1600';
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /** Deprecated sizes willen we uitfaseren. Gebruik deze dus NIET voor nieuwe situaties. */
    size?: ContainerSize;
  }>(),
  {
    size: 'md'
  }
);

const sizeClasses = {
  deprecated_768: 'tw-max-w-[768px]',
  deprecated_1200: 'tw-max-w-[1200px]',
  deprecated_1600: 'tw-max-w-[1600px]',
  sm: 'tw-max-w-[1046px]',
  md: 'tw-max-w-[1328px]'
} as const;

const sizeClass = computed(() => sizeClasses[props.size]);
</script>
<template>
  <div :class="['tw-mx-auto tw-box-content tw-w-[calc(100%-3rem)] tw-grow tw-px-6', sizeClass]">
    <slot></slot>
  </div>
</template>

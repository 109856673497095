export interface ProblemDetail {
  [index: string]: string[];
}

export interface ProblemDetailsResponse {
  data: ProblemDetails;
}

export interface ProblemDetails {
  title: string;
  status?: number;
  traceId?: string;
  type?: string;
  errors: ProblemDetail;
}

export const emptyProblemDetails = (title: string | undefined): ProblemDetails => ({
  title: title ?? '',
  errors: {}
});

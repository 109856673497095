import { createApp, App } from 'vue';
import { ApplicationInsightsOptions } from '@application-insights';
import axios from 'axios';
import appDefaults from '@app-lib';
import '@tailwind-preset/tailwind.css';
import { createMemoryHistory, createRouter } from 'vue-router';
import application from './App.vue';

const app: App<Element> = createApp(application);

const aiOptions: ApplicationInsightsOptions = {
  applicationName: 'menu-app',
  axios,
  trackInitialPageView: true
};

// Menu-app gebruikt zelf geen router
// maar is nodig omdat andere compnenten verwachten dat er altijd een router geladen is
const router = createRouter({
  history: createMemoryHistory(),
  routes: []
});
app.use(appDefaults, aiOptions).use(router).mount('#sb-menu-app');

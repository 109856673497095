import { MenuContext } from 'primevue/menu';

export type VMenuTheme = 'primary' | 'secondary';

const getTextColor = (theme?: VMenuTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'tw-text-white';
    default:
      return 'tw-text-primary-900';
  }
};

const getSubmenuheaderTextColor = (theme?: VMenuTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'tw-text-white/50';
    default:
      return 'tw-text-primary-900';
  }
};

const getBackgroundColor = (theme?: VMenuTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'tw-bg-primary-800';
    case 'secondary':
      return 'tw-bg-grey-100';
    default:
      return 'tw-bg-white';
  }
};

const getActiveBackgroundColor = (theme?: VMenuTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'tw-bg-primary-600';
    case 'secondary':
      return 'tw-bg-grey-200';
    default:
      return 'tw-bg-grey-100';
  }
};

const getHoverColor = (theme?: VMenuTheme | undefined) => {
  switch (theme) {
    case 'primary':
      return 'hover:tw-bg-white/25';
    case 'secondary':
      return 'hover:tw-bg-grey-200';
    default:
      return 'hover:tw-bg-grey-100';
  }
};

export const getPassthroughStyles = (theme?: VMenuTheme | undefined, isPopup = false) => ({
  root: {
    class: [
      getTextColor(theme),
      getBackgroundColor(theme),
      {
        'tw-rounded': isPopup,
        'tw-shadow-sm': isPopup,
        'tw-border tw-border-solid tw-border-grey-100': isPopup,
        'tw-p-1': isPopup,
        'tw-z-dialog': isPopup,
        'tw-mt-0.5': isPopup
      }
    ]
  },
  item: {
    class: ['tw-cursor-pointer', getTextColor(theme)]
  },
  itemContent: ({ context }: { context: MenuContext }) => ({
    class: [
      getTextColor(theme),
      getHoverColor(theme),
      {
        [getActiveBackgroundColor(theme)]: context.focused
      }
    ]
  }),
  itemlink: {
    class: [getTextColor(theme)]
  },
  submenuLabel: {
    class: [getSubmenuheaderTextColor(theme)]
  }
});

export const getStylesObj = (theme?: VMenuTheme | undefined) => ({
  textColor: getTextColor(theme),
  submenuLabelTextColor: getSubmenuheaderTextColor(theme),
  bgColor: getBackgroundColor(theme),
  activeBgColor: getActiveBackgroundColor(theme),
  hoverColor: getHoverColor(theme)
});

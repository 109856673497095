export enum ModalType {
  Secondary,
  Success,
  Danger
}

export enum Colors {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info'
}

import apiService from '@services/base/apiService';
import { RoleMatrixApi, UserApiApi, RoleApi, UserRoleApi } from '@services/identity-api/api';
import { env } from '@configuration-lib';

const baseUrl = env.VUE_APP_IDENTITY_BASE_URL;

export const userApi = apiService.create(UserApiApi, baseUrl);
export const roleMatrixApi = apiService.create(RoleMatrixApi, baseUrl);
export const roleApi = apiService.create(RoleApi, baseUrl);
export const userRoleApi = apiService.create(UserRoleApi, baseUrl);

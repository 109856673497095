// eslint-disable-next-line max-classes-per-file
import { AxiosError, isAxiosError } from 'axios';
import { ProblemDetails } from './problemDetails';

export type SbAxiosError<T = any, D = any> = AxiosError<T, D> & { sbError: SbError };
export type SbError = ValidationError | ForbiddenError | NotFoundError | Conflict | TechnicalError | TimeoutError;
export abstract class BaseError extends Error {
  public correlationId?: string;

  constructor(message?: string, correlationId?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = this.constructor.name;
    this.correlationId = correlationId;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class ValidationError extends BaseError {
  public problemDetails: ProblemDetails;

  constructor(problemDetails: ProblemDetails, message?: string, correlationId?: string, options?: ErrorOptions) {
    super(message, correlationId, options);
    this.problemDetails = problemDetails;
  }
}

export class ForbiddenError extends BaseError {}
export class NotFoundError extends BaseError {}
export class Conflict extends BaseError {}
export class TechnicalError extends BaseError {}
export class TimeoutError extends BaseError {}

export function isSbAxiosError<T = any, D = any>(error: any): error is SbAxiosError<T, D> {
  return error?.sbError !== undefined && isAxiosError(error);
}

export interface NotificationParamsWithTimestamp extends NotificationParams {
  id: string;
  timestamp: Date;
}

export interface NotificationParams {
  duration?: number;
  message: string;
  position?: Position;
  variant?: Colors;
  closable?: boolean;
  indefinite?: boolean;
  onclose?: () => void;
}

export enum Position {
  Top = 'top',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  Bottom = 'bottom',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right'
}
export enum Colors {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info'
}

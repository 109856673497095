<script lang="ts" setup>
import { computed } from 'vue';

import { SBLogo, SUREbusinessLogo } from '@assets/icons';
import { useClaims } from '@auth';
import { environmentName, showBanner } from '@environment-lib';
import { VChip, VChipProps } from '@shared/component';
import { getSurenetUrl } from '../../../urlHelper';
import BrandingLogo from './BrandingLogo.vue';

const claims = useClaims();

const bannerColor = computed<VChipProps['color']>(() => {
  if (environmentName === 'test') return 'red';
  if (environmentName === 'acceptatie') return 'blue';
  return 'orange';
});
</script>

<template>
  <Component
    class="tw-p-2"
    :class="{
      'tw-grid tw-grow': !claims?.isTemporaryUser
    }"
    v-bind="{
      ...(!claims?.isTemporaryUser && { href: getSurenetUrl('/content/', false) })
    }"
    :is="claims?.isTemporaryUser ? 'div' : 'a'">
    <div class="tw-relative tw-flex tw-h-full tw-items-center tw-justify-center">
      <template v-if="!claims?.isTemporaryUser">
        <SBLogo class="tw-h-6 3xl:tw-hidden" />

        <SUREbusinessLogo class="tw-h-6 -tw-translate-y-[5px] max-3xl:tw-hidden" />
      </template>

      <BrandingLogo v-else />

      <VChip
        v-if="showBanner"
        class="tw-absolute -tw-right-3 tw-top-0 tw-rotate-12 tw-capitalize"
        :color="bannerColor"
        :label="environmentName"
        size="sm" />
    </div>
  </Component>
</template>

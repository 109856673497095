<script lang="ts" setup>
import { FontAwesomeIcon } from '@shared/plugin';
import { useMediaQuery } from '@vueuse/core';
import { useSlots, watch } from 'vue';

export interface Props {
  open?: boolean;
  preventClose?: boolean;
  width?: 800;
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  preventClose: false
});

const emit = defineEmits<{ close: [] }>();

const slots = useSlots();
const isLargeScreen = useMediaQuery(`(min-width: 900px)`);

watch(
  () => props.open,
  value => {
    scrollLock(value);
  },
  { immediate: true }
);

function scrollLock(lock: boolean) {
  if (lock) {
    document.documentElement.classList.add('scroll-lock');
  } else {
    document.documentElement.classList.remove('scroll-lock');
  }
}
</script>

<template>
  <div
    :class="{ open }"
    class="side-panel tw-flex tw-flex-col tw-overflow-auto"
    :style="{
      maxWidth: width && isLargeScreen ? `${width}px` : '100%'
    }">
    <div class="header tw-sticky tw-top-0 tw-z-20 tw-shrink-0 tw-bg-inherit">
      <button
        class="panel-close tw-bg-inherit"
        :disabled="preventClose"
        @click.stop.prevent="preventClose ? null : emit('close')"
        type="button">
        <FontAwesomeIcon icon="fa-regular fa-times" />
      </button>
      <span v-if="$slots.header" class="header-title">
        <slot name="header"></slot>
      </span>
    </div>
    <div class="content tw-flex tw-grow tw-flex-col tw-bg-inherit">
      <div class="panel-body tw-grow tw-px-8">
        <slot></slot>
      </div>
      <div
        v-show="slots.footer"
        :class="{ 'md:tw-w-[75vw]': !props.width, 'md:tw-w-[800px]': props.width === 800 }"
        class="panel-footer tw-fixed tw-bottom-0 tw-z-dialog tw-w-full tw-justify-end tw-border-0 tw-border-t tw-border-solid tw-border-t-grey-200 tw-bg-inherit tw-px-8 tw-py-4">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
  <Transition name="fade">
    <div v-if="open" @click.stop.prevent="preventClose ? null : emit('close')" class="backdrop"></div>
  </Transition>
</template>

<style lang="scss" scoped>
.backdrop {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.side-panel {
  width: 100%;
  position: fixed;
  z-index: 201;
  top: 0;
  right: -100%;
  background: $white;
  height: 100%;
  transition: right 0.3s ease-in-out;
  &.open {
    right: 0;
  }
  @include for-size(tablet-portrait-up) {
    width: 75vw;
    max-width: 960px;
  }
}
.panel-close {
  border-radius: 0;
  height: 100%;
  padding: 0 1em;
  border: none;
  font-size: 1.5em;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
.header {
  height: 4em;
  display: flex;
  align-items: center;

  .header-title {
    background: var(--primary-dark);
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 2em;
    color: $white;
    ::v-deep(*) {
      color: $white;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  justify-content: space-between;

  .panel-body {
    width: 100%;
    padding: 0.5em;
  }
  .panel-footer {
    display: flex;
    align-items: center;
  }
}
</style>

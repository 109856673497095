import { computed, Ref } from 'vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { userQueries } from '@services/identity-api/queries/User';
import { IdentityWebModelsUserUserClaimDto } from '@services/identity-api/api';
import { FlattenedPermissions } from './types';

const checkClaim = (claims: Ref<IdentityWebModelsUserUserClaimDto[]> | Ref<undefined>) => (type: string) =>
  claims.value?.some(d => d.type === type && d.value === 'True') ?? false;

export function useClaims() {
  const {
    data: claims,
    isLoading,
    isSuccess
  } = useQuery({
    ...userQueries.getClaims,
    select: e => e.data,
    staleTime: 60 * 1000,
    retry: 10
  });
  const hasClaim = checkClaim(claims);

  return computed(() => ({
    isInitialLoading: isLoading.value,
    magBeheerboxInzien: hasPermission(claims.value ?? [], 'Inregeling.InregelingInzien'),
    magAdviseurbeheerInzien: hasPermission(claims.value ?? [], 'Crm.Adviseurbeheer'),
    isSuccess: isSuccess.value,
    isWebmodule: claims.value?.some(d => d.type === 'WebmoduleId' && d.value !== '0') ?? false,
    isTemporaryUser: hasClaim('IsTemporaryUser'),
    isInternal: hasClaim('IsInternal'),
    isFinancieel: hasClaim('HeeftFinancieelPermissie'),
    heeftInstellingenPermissie: hasClaim('HeeftInstellingenPermissie'),
    isMaatschappij: hasClaim('IsMaatschappij'),
    isAdviseur: hasClaim('IsAdviseur'),
    webmoduleId: claims.value?.some(d => d.type === 'WebmoduleId')
      ? Number(claims.value?.filter(d => d.type === 'WebmoduleId')[0].value)
      : undefined
  }));
}
function hasPermission(claims: IdentityWebModelsUserUserClaimDto[], permission: FlattenedPermissions): boolean {
  return claims.some(t => t.type === 'Permission' && t.value === permission);
}

export function useRefreshClaims(): { refresh: () => Promise<void> } {
  const queryClient = useQueryClient();
  return { refresh: () => queryClient.invalidateQueries(userQueries.getClaims) };
}

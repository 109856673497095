<script setup lang="ts">
import Skeleton, { SkeletonProps } from 'primevue/skeleton';
import { computed, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{ shape?: SkeletonProps['shape']; height?: keyof typeof heights; count?: number; delayed?: boolean }>(),
  {
    shape: 'rectangle',
    height: 'md',
    count: 1,
    delayed: false
  }
);

const heights = {
  md: '1rem',
  lg: '2.5rem',
  xl: '4.5rem'
} as const;

const show = ref<boolean>(false);

const height = computed(() => heights[props.height]);

onMounted(() => setTimeout(() => (show.value = true), 1000));
</script>

<template>
  <span :class="{ 'tw-invisible': delayed ? !show : false }" class="tw-flex tw-w-full tw-flex-col tw-gap-2">
    <Skeleton v-for="i in count" :key="i" :shape="props.shape" :height />
  </span>
</template>

import { Adres } from './types';

export const parseAddressAsString = ({ huisnummer, huisnummertoevoeging, plaats, postcode, straat }: Adres) =>
  `${straat} ${huisnummer}${huisnummertoevoeging || ''}, ${postcode} ${plaats}`;

/**
 * Parses a string as a slug.
 * Removes special characters, converts to lowercase, trims whitespace,
 * replaces spaces and underscores with hyphens,
 * and removes leading and trailing hyphens.
 *
 * @param {string} string - The string to parse.
 * @returns {string} - The parsed string as a slug.
 */
export const parseStringAsSlug = (string: string) =>
  string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

/**
 * Parse a Dutch postal code by inserting a space.
 * Assumes the input is a valid Dutch postal code without space, i.e., '1234AB'.
 *
 * @param {string} postcode - The Dutch postcode.
 * @returns {string} The formatted postcode, i.e., '1234 AB'.
 */
export const parsePostcode = (postcode: string): string => {
  if (postcode.length === 6) {
    const numberPart = postcode.slice(0, 4);
    const letterPart = postcode.slice(4);
    return `${numberPart} ${letterPart.toUpperCase()}`;
  }

  return postcode;
};

/**
 * Decodes HTML entities in the given input.
 *
 * @param {string} input - The input string possibly containing HTML entities.
 * @return {string} - The decoded string without HTML entities.
 *  @example
 *  ```typescript
 * const encodedString = '&lt;p&gt;Hello, world!&lt;/p&gt;';
 * const decodedString = htmlDecode(encodedString);

 *  console.log(decodedString); // <p>Hello, world!</p>
 * ```
 */
export function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

<script lang="ts" setup>
import { VChip, VChipProps } from '@shared/component';
import { computed } from 'vue';
import { tryMenuNavigation } from '../../urlHelper';

const props = defineProps<{
  colorName: string;
  roundedValue: string;
}>();

const chipData = computed<{
  color: VChipProps['color'];
  icon: VChipProps['icon'];
}>(() => {
  switch (props.colorName) {
    case 'rood':
      return {
        color: 'red',
        icon: ['far', 'gauge-max']
      };
    case 'oranje':
      return {
        color: 'orange',
        icon: ['far', 'gauge']
      };
    case 'groen':
      return {
        color: 'teal',
        icon: ['far', 'gauge-min']
      };
    default:
      return {
        color: undefined,
        icon: ['far', 'gauge']
      };
  }
});
</script>

<template>
  <a
    class="tw-flex tw-items-center tw-no-underline"
    href="/net/rapportageintern/portefeuilledashboard"
    @click="tryMenuNavigation">
    <VChip :color="chipData.color" hide-icon="md" :icon="chipData.icon" :label="props.roundedValue" />
  </a>
</template>

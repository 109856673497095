<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VMenu } from '@shared/component';
import type { MenuItemCommandEvent } from 'primevue/menuitem';
import { useToggle } from '@vueuse/core';
import { defineModel, ref } from 'vue';
import { tryMenuNavigation } from '../../urlHelper';
import ServiceDeskModal from '../servicedesk/ServiceDeskModal.vue';

const props = withDefaults(
  defineProps<{
    alwaysSmall?: boolean;
    iconStyle?: string;
  }>(),
  { iconStyle: 'far' }
);

const searchUserOpen = defineModel<boolean>('searchUserOpen');

const menu = ref();

const [servicedeskModalOpen, toggleServicedeskModal] = useToggle();

function toggleMenu(event: Event) {
  menu.value.toggle(event);
}
</script>

<template>
  <div class="tw-relative tw-flex">
    <button
      class="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-border-none tw-bg-transparent tw-px-3 tw-text-sm tw-text-inherit tw-outline-none"
      :class="{
        'lg:tw-px-6': !props.alwaysSmall
      }"
      type="button"
      @click="toggleMenu">
      <FontAwesomeIcon :icon="[props.iconStyle, 'users']" />

      <span
        class="tw-hidden"
        :class="{
          'xl:tw-display-unset': !props.alwaysSmall
        }">
        Intern
      </span>
    </button>

    <VMenu
      append-to="self"
      class="tw-left-auto tw-right-0 tw-top-full"
      :class="{
        'xl:tw-left-0 xl:tw-right-auto': !props.alwaysSmall
      }"
      :model="[
        {
          command: ({ originalEvent }: MenuItemCommandEvent) =>
            tryMenuNavigation(originalEvent, '/content/boaz/werkverdeling'),
          icon: ['far', 'sitemap'],
          label: 'Werkverdeling',
          url: '/content/boaz/werkverdeling'
        },
        {
          icon: ['far', 'chart-bar'],
          label: 'Monitor',
          target: '_blank',
          url: '/net/rapportageintern/sb_monitor'
        },
        {
          command: ({ originalEvent }: MenuItemCommandEvent) => tryMenuNavigation(originalEvent, '/content/agenda'),
          icon: ['far', 'bell'],
          label: 'Agenda',
          url: '/content/agenda'
        },
        {
          command: () => (searchUserOpen = !searchUserOpen),
          icon: ['far', 'search'],
          label: 'Gebruiker zoeken'
        },
        {
          command: toggleServicedeskModal,
          icon: ['far', 'user-headset'],
          label: 'Servicedesk'
        }
      ]"
      popup
      ref="menu" />

    <ServiceDeskModal v-model:is-open="servicedeskModalOpen" />
  </div>
</template>

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { env } from '@configuration-lib';

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3
}

let applicationInsight: ApplicationInsights | null = null;
export const initApplicationInsight = (ai: ApplicationInsights) => (applicationInsight = ai);
const MINIMUM_LOG_LEVEL_KEY = 'MINIMUM_LOG_LEVEL';

declare global {
  interface Window {
    // eslint-disable-next-line no-unused-vars
    setMinimumLogLevel: (logLevel: LogLevel) => void;
  }
}

window.setMinimumLogLevel = (logLevel: LogLevel) => {
  localStorage.setItem(MINIMUM_LOG_LEVEL_KEY, logLevel.toString());
};

const getMinimumLogLevel = () => {
  return parseInt(localStorage.getItem(MINIMUM_LOG_LEVEL_KEY) ?? '99', 10);
};

const shouldLog = (logLevel: LogLevel): boolean => {
  return getMinimumLogLevel() <= logLevel;
};

const info = (message?: any, ...optionalParams: any[]) => {
  if (env.NODE_ENV !== 'production' || shouldLog(LogLevel.Info)) {
    console.log(`INFO: ${message}`, ...optionalParams);
  }
  applicationInsight?.trackTrace({ message, severityLevel: SeverityLevel.Information }, optionalParams);
};

const debug = (message?: any, ...optionalParams: any[]) => {
  if (env.NODE_ENV !== 'production' || shouldLog(LogLevel.Debug)) {
    console.log(`DEBUG: ${message}`, ...optionalParams);
  }
};

const warn = (message?: any, ...optionalParams: any[]) => {
  if (env.NODE_ENV !== 'production' || shouldLog(LogLevel.Warn)) {
    console.warn(`WARN: ${message}`, ...optionalParams);
  }
  applicationInsight?.trackTrace({ message, severityLevel: SeverityLevel.Warning }, optionalParams);
};

const error = (message?: any, ...optionalParams: any[]) => {
  if (env.NODE_ENV !== 'production' || shouldLog(LogLevel.Error)) {
    console.error(`ERROR: ${message}`, ...optionalParams);
  }
  const exception = message instanceof Error ? message : new Error(message);
  applicationInsight?.trackException({ exception, severityLevel: SeverityLevel.Critical }, optionalParams);
};

export const logger = {
  debug,
  info,
  warn,
  error
};

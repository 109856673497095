<script setup lang="ts">
import { VModal } from '@shared/component';
import { FontAwesomeIcon } from '@shared/plugin';

const isOpen = defineModel<boolean>('isOpen');
</script>
<template>
  <VModal
    :is-active="isOpen"
    title="SUREbusiness Servicedesk"
    confirm-button-text=""
    cancel-button-text="Sluiten"
    @close-modal="isOpen = false"
    @deny-action="isOpen = false"
    @confirm-action="isOpen = false">
    <div class="tw-pt-3 tw-text-center">
      <FontAwesomeIcon icon="fa-solid fa-user-headset" size="3x" class="tw-pb-6 tw-text-success" />

      <p class="tw-mb-2">
        Heb je een probleem, issue of een vraag? Maak hier een
        <a target="_blank" href="https://surebusiness.topdesk.net/tas/public" rel="noopener noreferrer">MELDING</a> aan!
      </p>
      <p class="tw-mb-2">
        Weet je het niet zeker? Volg dan ons
        <a
          target="_blank"
          href="https://sureb.sharepoint.com/:b:/r/sites/SUREbusiness/Shared%20Documents/General/Applicatiebeheer/Debora/Stroomschema%20Servicedesk.pdf?csf=1&web=1&e=X2rsNQ"
          rel="noopener noreferrer"
          >stroomschema </a
        >.
      </p>
      <p class="tw-mb-2">
        Twijfel je nog steeds? Bel dan naar <a href="tel:0723035911">072 – 303 59 11</a> of stuur een mail naar
        <a href="mailto:servicedesk@surebusiness.nl">servicedesk@surebusiness.nl</a>.
      </p>
    </div>
  </VModal>
</template>

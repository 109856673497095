<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    align?: 'left' | 'center' | 'right';
    attached?: boolean;
  }>(),
  { align: 'left' }
);

const justify = {
  left: 'tw-justify-start',
  center: 'tw-justify-center',
  right: 'tw-justify-end'
};
</script>

<template>
  <div
    class="tw-flex tw-flex-wrap"
    :class="{
      [justify[props.align]]: true,
      'tw-group/v-button-group--attached': props.attached,
      'tw-items-center tw-gap-2': !props.attached
    }">
    <slot></slot>
  </div>
</template>

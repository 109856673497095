import { createQueryKeys } from '@lukemorales/query-key-factory';
import { MaybeRef, unref } from 'vue';
import { brandingApi } from '../service';

export const brandingQueries = createQueryKeys('branding', {
  fetchBranding: (adviseurnummer?: MaybeRef<number | undefined>) => ({
    queryKey: ['branding', adviseurnummer],
    queryFn: () => brandingApi.brandingGet(unref(adviseurnummer))
  })
});

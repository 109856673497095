import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  ProblemDetails,
  ProblemDetailsResponse,
  ValidationError,
  TechnicalError,
  Conflict,
  ForbiddenError,
  NotFoundError,
  BaseError,
  emptyProblemDetails,
  TimeoutError
} from '@errors';
import { useTimeoutStore } from '@services/base/timeoutStore';
import { env } from '@configuration-lib';
import { unref } from 'vue';

import { Configuration } from './configuration';

export class ApiService {
  private accessToken = '';

  private basePath: string;

  private config: Configuration;

  public apiClient: AxiosInstance;

  constructor(basePath: string, appToken: string) {
    if (appToken) this.accessToken = appToken;
    this.basePath = basePath;
    this.apiClient = this.ConstructApiClient();
    this.config = new Configuration({ accessToken: this.accessToken });
  }

  private ConstructApiClient(): AxiosInstance {
    const apiClient = axios.create({
      withCredentials: false,
      baseURL: this.basePath,
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      },
      timeout: 30 * 1000,
      timeoutErrorMessage: 'timeout'
    });

    apiClient.interceptors.request.use(
      req => {
        const timeoutStore = useTimeoutStore();
        timeoutStore.updateLastRequest();
        return req;
      },
      err => {
        const timeoutStore = useTimeoutStore();
        timeoutStore.updateLastRequest();
        return err;
      }
    );

    apiClient.interceptors.response.use(
      (response: any) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      },
      (error: any) => {
        if (!axios.isAxiosError(error)) return Promise.reject(error);

        if (error.message === 'timeout') {
          const problemDetails: ProblemDetails = {
            title: 'Je aanvraag heeft helaas een time-out gehad, probeer het opnieuw.',
            type: 'timeout',
            errors: {}
          };
          error.response = { ...error.response!, data: [] };
          error.response.data = { ...problemDetails, errors: [] };

          const errorRejection = ApiService.MergeToAxiosError(error, error.response);
          return Promise.reject(errorRejection);
        }

        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            const loginUrl = `https://${window.location.host}/Identity/Authentication/Login?returnUrl=`;
            const returnUrl = `${window.location.pathname}${window.location.search}`;
            window.location.href = `${loginUrl}${encodeURIComponent(returnUrl)}`;
            return Promise.resolve();
          }

          const errorRejection = ApiService.MergeToAxiosError(error, error.response);

          return Promise.reject(errorRejection);
        }
      }
    );
    return apiClient;
  }

  static MergeToAxiosError(error: AxiosError, addtionalProps: object): AxiosError {
    // Dit is niet de meest mooie constructie maar omdat de oude error afhandeling in de apps nog de losse response props verwacht.
    // Er voor gekozen deze samen te voegen en hem weer terug te typen naar een axios error.

    const errorRejection = {
      ...error,
      ...addtionalProps,
      sbError: ApiService.MapToSurebusinessError(error)
    };

    // Set the prototype of errorRejection to the same prototype as the original error
    Object.setPrototypeOf(errorRejection, Object.getPrototypeOf(error));
    return errorRejection;
  }

  public create<T>(
    // @ts-ignore

    Type: new (configuration: Configuration, basePath: string, axiosInstance: AxiosInstance) => T,
    baseUrl?: string
  ): T {
    return new Type(this.config, baseUrl || this.basePath, this.apiClient);
  }

  static MapToSurebusinessError(error: AxiosError): BaseError {
    let message: string | undefined = 'Onbekende foutmelding';

    if (error.response && typeof error === 'object') {
      // The request was made and the server responded with a status code
      const typedError = error as { response?: { data?: { title?: string } } };
      message = typedError.response?.data?.title ?? undefined;
    }

    const options = { cause: error };
    const data = error.response?.data as any;
    const traceId = data?.traceId;

    switch (error.response?.status) {
      case 400:
        if (isProblemDetail(error.response)) {
          const { title } = error.response.data;
          return new ValidationError(error.response.data, title, traceId, options);
        }
        return new ValidationError(emptyProblemDetails(message), message, traceId, options);
      case 403:
        return new ForbiddenError(message, traceId, options);
      case 404:
        return new NotFoundError(message, traceId, options);
      case 408:
        return new TimeoutError(message, traceId, options);
      case 409:
        return new Conflict(message, traceId, options);
      default:
        return new TechnicalError(message, traceId, options);
    }
  }
}

const apiService = new ApiService(env.VUE_APP_SURENETAPI_API ?? '', env.VUE_APP_TOKEN ?? '');

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = axios.CancelToken.source();
  if (!config.signal) config.cancelToken = source.token;

  const baseUrl = env.VUE_APP_MANAGEMENT_BASE_URL;

  config.url = baseUrl + config.url;

  const promise = apiService
    .apiClient({
      ...config,
      ...options,
      params: unref(config.params)
    })
    .then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    if (!config.signal) source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export default apiService;

export function isProblemDetail(response: ProblemDetailsResponse | any): response is ProblemDetailsResponse {
  return !!(response.status === 400 && response.data?.errors);
}

export const isInternalServerError = (axiosError: any | AxiosError): axiosError is AxiosError =>
  axiosError?.response.status >= 500 && axiosError?.response.status < 600;

export function formatProblemDetails(response: ProblemDetailsResponse): string {
  const errors = Object.values(response.data.errors).reduce((a, b) => a.concat(b), []);
  if (errors.length === 0) return response.data.title;
  return errors.length > 1 ? errors.map(e => `<p> - ${e}</p>`).join('') : errors[0];
}

import { userApi } from '@services/identity-api/identityService';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const userQueries = createQueryKeys('user', {
  getClaims: {
    queryKey: null,
    queryFn: () => userApi.apiV1UserClaimsGet()
  },
  getProfile: {
    queryKey: null,
    queryFn: () => userApi.apiV1UserProfileGet()
  }
});

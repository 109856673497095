<script setup lang="ts">
import { FontAwesomeIcon } from '@shared/plugin';
import { computed } from 'vue';

type Variant = 'primary' | 'danger' | 'white';

const props = withDefaults(defineProps<{ variant?: Variant }>(), {
  variant: 'primary'
});

const color = computed(() => {
  switch (props.variant) {
    case 'danger':
      return 'var(--secondary)';
    case 'white':
      return '#fff';
    case 'primary':
    default:
      return 'var(--primary)';
  }
});
</script>

<template>
  <div class="v-spinner-v2 tw-inline-flex tw-px-2 tw-py-3">
    <FontAwesomeIcon class="v-spinner-v2__icon" :icon="['fad', 'spinner-third']" spin />
  </div>
</template>

<style lang="scss" scoped>
.v-spinner-v2 {
  &__icon {
    color: v-bind(color);
    animation-duration: 0.65s;
  }
}
</style>

/* eslint-disable no-restricted-properties */
import { z } from 'zod';

const envSchema = z.object({
  VUE_APP_SURENETAPI_API: z.string(),
  VUE_APP_ELLA_API: z.string(),
  VUE_APP_MANAGEMENT_BASE_URL: z.string(),
  VUE_APP_IDENTITY_BASE_URL: z.string(),
  VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN: z.string(),
  VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN_OUD: z.string(),
  VUE_APP_SKIP_INSIGHTS: z.boolean(),
  VUE_APP_MOCK_API_CALLS: z.boolean(),
  VUE_APP_TOKEN: z.string().optional(),
  VUE_APP_BASE_URL_MENU: z.string().optional(),
  VUE_APP_USE_MOCK_AUTHENTICATION: z.boolean(),
  NODE_ENV: z.string(),
  BASE_URL: z.string()
});

type Env = z.infer<typeof envSchema>;

const parseBoolean = (value: string) => value === 'true';

const env: Env = {
  VUE_APP_SURENETAPI_API: import.meta.env.VUE_APP_SURENETAPI_API,
  VUE_APP_ELLA_API: import.meta.env.VUE_APP_ELLA_API,
  VUE_APP_MANAGEMENT_BASE_URL: import.meta.env.VUE_APP_MANAGEMENT_BASE_URL,
  VUE_APP_IDENTITY_BASE_URL: import.meta.env.VUE_APP_IDENTITY_BASE_URL,
  VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN: import.meta.env.VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN,
  VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN_OUD: import.meta.env.VUE_APP_FOLDER_MAATSCHAPPIJLOGOS_KLEIN_OUD,
  VUE_APP_SKIP_INSIGHTS: parseBoolean(import.meta.env.VUE_APP_SKIP_INSIGHTS),
  VUE_APP_MOCK_API_CALLS: parseBoolean(import.meta.env.VUE_APP_MOCK_API_CALLS),
  VUE_APP_BASE_URL_MENU: import.meta.env.VUE_APP_BASE_URL_MENU,
  VUE_APP_TOKEN: import.meta.env.VUE_APP_TOKEN,
  VUE_APP_USE_MOCK_AUTHENTICATION: parseBoolean(import.meta.env.VUE_APP_USE_MOCK_AUTHENTICATION),
  BASE_URL: import.meta.env.BASE_URL,
  NODE_ENV: import.meta.env.MODE
} as const;

// Kan hier niet de log lib gebruiken ivm circulaire dependecy.
// console.log('Using following environment: ', env);

envSchema.parse(env);

export { env, Env };

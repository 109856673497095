<script lang="ts" setup>
const props = defineProps<{
  useCurrentColor?: boolean;
}>();
</script>

<template>
  <svg viewBox="0 0 31 25" xmlns="http://www.w3.org/2000/svg">
    <path
      :class="{ 'tw-fill-current': props.useCurrentColor }"
      d="M12.9267 3.40821C11.6679 2.50928 10.1506 2.07027 8.61888 2.16178C5.38799 2.16178 3.32592 3.79122 3.32592 6.14126C3.32592 8.78343 6.13553 9.8838 9.08451 11.0848C12.9236 12.5714 16.6233 14.0126 16.6233 18.1381C16.6233 22.4065 12.9711 25 8.24194 25C3.2784 25 0 22.3611 0 20.0078C0 18.6185 0.750707 17.7032 1.87202 17.7032C2.11141 17.6834 2.3522 17.7148 2.57916 17.7953C2.80612 17.8758 3.0143 18.0037 3.19055 18.1709C3.3668 18.338 3.50727 18.5409 3.60309 18.7666C3.69891 18.9922 3.74798 19.2358 3.7472 19.482C3.73264 20.1099 3.51918 20.716 3.13903 21.2088C4.61259 22.3573 6.44225 22.92 8.28946 22.7928C11.986 22.7928 14.1399 21.0173 14.1399 18.281C14.1399 15.7394 11.1941 14.5871 8.52069 13.5322C4.4504 11.9482 0.795053 10.5557 0.795053 6.38146C0.795053 2.59348 3.93409 0 8.71074 0C12.8761 0 16.107 2.16178 16.107 4.8007C16.1263 5.05672 16.0952 5.31412 16.0156 5.55758C15.9359 5.80104 15.8092 6.0256 15.6432 6.21789C15.4771 6.41018 15.275 6.56629 15.0488 6.67691C14.8227 6.78754 14.5771 6.85043 14.3268 6.86185C14.0753 6.88357 13.8222 6.85121 13.5836 6.76686C13.3451 6.6825 13.1263 6.548 12.9415 6.37199C12.7566 6.19597 12.6096 5.98232 12.51 5.74471C12.4103 5.50711 12.3603 5.2508 12.3629 4.99221C12.3828 4.41696 12.5789 3.86274 12.9236 3.40821H12.9267Z"
      fill="#00A19A" />
    <path
      :class="{ 'tw-fill-current': props.useCurrentColor }"
      d="M19.0086 22.3416L18.5968 24.2242H18.2167V1.2302H15.8379V0.581017H18.9738V13.2141C19.6146 12.4178 20.4182 11.7755 21.3279 11.3324C22.2375 10.8893 23.231 10.6563 24.2382 10.6498C25.1022 10.6465 25.9576 10.8255 26.7512 11.1757C27.5447 11.5259 28.2594 12.0398 28.8508 12.6853C29.4421 13.3308 29.8974 14.0942 30.1883 14.9279C30.4792 15.7616 30.5995 16.6478 30.5416 17.5312C30.5802 18.4238 30.4415 19.3151 30.1338 20.1511C29.8261 20.9871 29.3558 21.7503 28.7515 22.3945C28.1472 23.0386 27.4216 23.5502 26.6185 23.8982C25.8154 24.2462 24.9517 24.4234 24.0798 24.419C22.1964 24.3957 20.3887 23.6552 19.0086 22.3416ZM29.7497 17.5344C29.8172 16.7449 29.7242 15.9496 29.4766 15.1988C29.2291 14.4479 28.8324 13.7578 28.3116 13.1719C27.7907 12.586 27.1571 12.1171 26.4505 11.7948C25.744 11.4724 24.9799 11.3036 24.2065 11.299C23.1906 11.3038 22.1895 11.5495 21.2817 12.0169C20.3738 12.4843 19.5839 13.1607 18.9738 13.9931V21.5301C20.3176 22.9405 22.1532 23.7461 24.0767 23.7698C24.8567 23.7686 25.6281 23.6032 26.3431 23.2838C27.0582 22.9645 27.7016 22.4981 28.2335 21.9135C28.7654 21.3289 29.1744 20.6385 29.4352 19.8852C29.696 19.132 29.8031 18.3318 29.7497 17.5344Z"
      fill="#025551" />
  </svg>
</template>

const getPageButton = ({ context }: Record<string, any>) => ({
  class: [
    'tw-group tw-relative',

    // Flex & Alignment
    'tw-inline-flex tw-items-center tw-justify-center',

    // Shape
    'tw-border-0 tw-rounded',

    // Size
    'tw-min-w-8 tw-h-10',
    'tw-leading-none',

    // Color
    'tw-text-grey-500',

    // State
    {
      'hover:tw-bg-grey-200': !context.disabled,
      'focus:tw-outline-none': !context.disabled
    },

    // Transition
    'tw-transition tw-duration-200',

    // Misc
    'tw-user-none tw-overflow-hidden',
    { 'tw-cursor-default tw-pointer-events-none': context.disabled }
  ]
});

const getPageIcon = ({ context }: Record<string, any>) => ({
  class: [
    'tw-text-sm group-hover:tw-text-primary-900',
    {
      'tw-text-grey-500': !context.disabled,
      'tw-text-grey-200': context.disabled
    }
  ]
});

export const paginator = {
  content: {
    class: [
      // Flex & Alignment
      'tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-gap-1.5',

      // Shape
      'tw-border-0 tw-rounded-md',

      // Color
      'tw-bg-surface-0',
      'tw-text-grey-100'
    ]
  },
  first: getPageButton,
  firstIcon: getPageIcon,
  prev: getPageButton,
  prevIcon: getPageIcon,
  next: getPageButton,
  nextIcon: getPageIcon,
  last: getPageButton,
  lastIcon: getPageIcon,
  page: ({ context }: Record<string, any>) => ({
    class: [
      'tw-relative',

      // Flex & Alignment
      'tw-inline-flex tw-items-center tw-justify-center',

      // Shape
      'tw-border-0 tw-rounded-full',

      // Size
      'tw-min-w-[2.5rem] tw-h-10',
      'tw-leading-none',

      // Color
      'tw-text-grey-500',

      // State
      {
        'hover:tw-bg-grey-100 )]': !context.disabled,
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 ': !context.disabled
      },

      // Transition
      'tw-transition tw-duration-200',

      // Misc
      'tw-user-none tw-overflow-hidden',
      { 'tw-cursor-default tw-pointer-events-none tw-opacity-60': context.disabled }
    ]
  }),
  pcRowPerPageDropdown: {
    root: ({ props, state }: Record<string, any>) => ({
      class: [
        // Display and Position
        'tw-inline-flex',
        'tw-relative',

        // Shape
        'tw-h-10',
        'tw-rounded-md',

        // Spacing
        'tw-mx-2',

        // Color and Background
        'tw-bg-surface-0',
        'tw-border tw-border-surface-300',

        // Transitions
        'tw-transition-all',
        'tw-duration-200',

        // States
        'hover:tw-border-surface-400',
        {
          'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 ': !state.focused
        },

        // Misc
        'tw-cursor-pointer',
        'tw-select-none',
        {
          'tw-bg-surface-200  tw-select-none tw-pointer-events-none tw-cursor-default': props.disabled
        }
      ]
    }),
    input: {
      class: [
        // Font
        'tw-leading-[normal]',

        // Display
        'tw-block',
        'tw-flex-auto',

        // Color and Background
        'tw-bg-transparent',
        'tw-border-0',
        'tw-text-surface-800',

        // Sizing and Spacing
        'tw-w-[1%]',
        'tw-py-2 tw-pl-3 tw-pr-0',

        // Shape
        'tw-rounded-none',

        // Transitions
        'tw-transition',
        'tw-duration-200',

        // States
        'focus:tw-outline-none focus:tw-shadow-none',

        // Misc
        'tw-relative',
        'tw-cursor-pointer',
        'tw-overflow-hidden tw-overflow-ellipsis',
        'tw-whitespace-nowrap',
        'tw-appearance-none'
      ]
    },
    trigger: {
      class: [
        // Flexbox
        'tw-flex tw-items-center tw-justify-center',
        'tw-shrink-0',

        // Color and Background
        'tw-bg-transparent',
        'tw-text-grey-500',

        // Size
        'tw-w-10',

        // Shape
        'tw-rounded-tr-md',
        'tw-rounded-br-md'
      ]
    },
    panel: {
      class: [
        // Colors
        'tw-bg-surface-0',
        'tw-text-surface-700',

        // Shape
        'tw-border tw-border-surface-300',
        'tw-rounded-md',
        'tw-shadow-md'
      ]
    },
    wrapper: {
      class: [
        // Sizing
        'tw-max-h-[200px]',

        // Misc
        'tw-overflow-auto'
      ]
    },
    list: {
      class: 'tw-p-1 tw-list-none tw-m-0'
    },
    item: ({ context }: Record<string, any>) => ({
      class: [
        'tw-relative',

        // Font
        'tw-leading-none',

        // Spacing
        'tw-m-0 tw-px-3 tw-py-2',
        'first:tw-mt-0 tw-mt-[2px]',

        // Shape
        'tw-border-0 tw-rounded',

        // Colors
        {
          'tw-text-surface-700': !context.focused || (context.focused && !context.selected),
          'tw-bg-surface-200': context.focused && !context.selected,
          'tw-text-primary-highlight-inverse': context.selected,
          'tw-bg-primary-highlight': context.selected
        },

        // States
        { 'hover:tw-bg-surface-100 )]': !context.focused && !context.selected },
        { 'hover:tw-bg-primary-highlight-hover': context.selected },
        {
          'hover:tw-text-surface-700 hover:tw-bg-surface-100  )]': context.focused && !context.selected
        },

        // Transitions
        'tw-transition-shadow',
        'tw-duration-200',

        // Misc
        'tw-cursor-pointer',
        'tw-overflow-hidden',
        'tw-whitespace-nowrap'
      ]
    })
  },
  pcJumpToPageInput: {
    root: {
      class: 'tw-inline-flex tw-mx-2'
    },
    pcInput: {
      root: {
        class: [
          'tw-relative tw-text-center',

          // Font
          'tw-leading-none',

          // Display
          'tw-block',
          'tw-flex-auto',

          // Colors
          'tw-text-grey-500',
          'tw-bg-white',
          'tw-border tw-border-grey-300',

          // Sizing and Spacing
          'tw-w-10',
          'tw-p-2 tw-m-0',

          // Shape
          'tw-rounded-md',

          // Transitions
          'tw-transition',
          'tw-duration-200',

          // States
          'hover:tw-border-surface-400',
          'focus:tw-outline-none focus:tw-shadow-none',

          // Misc
          'tw-overflow-hidden tw-overflow-ellipsis',
          'tw-whitespace-nowrap',
          'tw-appearance-none'
        ]
      }
    }
  },
  pcJumpToPageDropdown: {
    root: ({ props, state }: Record<string, any>) => ({
      class: [
        // Display and Position
        'tw-inline-flex',
        'tw-relative',

        // Shape
        'tw-h-10',
        'tw-rounded-md',

        // Spacing
        'tw-mx-2',

        // Color and Background
        'tw-bg-surface-0',
        'tw-border tw-border-surface-300',

        // Transitions
        'tw-transition-all',
        'tw-duration-200',

        // States
        'hover:tw-border-surface-400',
        {
          'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 ': !state.focused
        },

        // Misc
        'tw-cursor-pointer',
        'tw-select-none',
        {
          'tw-bg-surface-200  tw-select-none tw-pointer-events-none tw-cursor-default': props.disabled
        }
      ]
    }),
    input: {
      class: [
        // Font
        'tw-leading-[normal]',

        // Display
        'tw-block',
        'tw-flex-auto',

        // Color and Background
        'tw-bg-transparent',
        'tw-border-0',
        'tw-text-surface-800',

        // Sizing and Spacing
        'tw-w-[1%]',
        'tw-py-2 tw-pl-3 tw-pr-0',

        // Shape
        'tw-rounded-none',

        // Transitions
        'tw-transition',
        'tw-duration-200',

        // States
        'focus:tw-outline-none focus:tw-shadow-none',

        // Misc
        'tw-relative',
        'tw-cursor-pointer',
        'tw-overflow-hidden tw-overflow-ellipsis',
        'tw-whitespace-nowrap',
        'tw-appearance-none'
      ]
    },
    trigger: {
      class: [
        // Flexbox
        'tw-flex tw-items-center tw-justify-center',
        'tw-shrink-0',

        // Color and Background
        'tw-bg-transparent',
        'tw-text-grey-500',

        // Size
        'tw-w-10',

        // Shape
        'tw-rounded-tr-md',
        'tw-rounded-br-md'
      ]
    },
    panel: {
      class: [
        // Colors
        'tw-bg-surface-0',
        'tw-text-surface-700',

        // Shape
        'tw-border tw-border-surface-300',
        'tw-rounded-md',
        'tw-shadow-md'
      ]
    },
    wrapper: {
      class: [
        // Sizing
        'tw-max-h-[200px]',

        // Misc
        'tw-overflow-auto'
      ]
    },
    list: {
      class: 'tw-p-1 tw-list-none tw-m-0'
    },
    item: ({ context }: Record<string, any>) => ({
      class: [
        'tw-relative',

        // Font
        'tw-leading-none',

        // Spacing
        'tw-m-0 tw-px-3 tw-py-2',
        'first:tw-mt-0 tw-mt-[2px]',

        // Shape
        'tw-border-0 tw-rounded',

        // Colors
        {
          'tw-text-surface-700 ': !context.focused || (context.focused && !context.selected),
          'tw-bg-surface-200 ': context.focused && !context.selected,
          'tw-text-primary-highlight-inverse': context.selected,
          'tw-bg-primary-highlight': context.selected
        },

        // States
        { 'hover:tw-bg-surface-100 )]': !context.focused && !context.selected },
        { 'hover:tw-bg-primary-highlight-hover': context.selected },
        {
          'hover:tw-text-surface-700 hover:tw-bg-surface-100)]': context.focused && !context.selected
        },

        // Transitions
        'tw-transition-shadow',
        'tw-duration-200',

        // Misc
        'tw-cursor-pointer',
        'tw-overflow-hidden',
        'tw-whitespace-nowrap'
      ]
    })
  },
  contentStart: {
    class: 'tw-mr-auto'
  },
  contentEnd: {
    class: 'tw-ml-auto'
  },
  current: {
    class: 'tw-text-grey-500'
  }
};

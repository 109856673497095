<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { brandingQueries } from '@services/overig/queries/BrandingQueries';
import { VMenu } from '@shared/component';
import { useQuery } from '@tanstack/vue-query';
import { computed, ref } from 'vue';

const menu = ref<InstanceType<typeof VMenu>>();

const { data: brandingData } = useQuery({
  ...brandingQueries.fetchBranding(),
  select: result => result.data.result
});

const model = computed(() => {
  const items = [];

  if (brandingData.value?.telefoonnummer) {
    items.push({
      icon: ['far', 'phone'],
      label: brandingData.value.telefoonnummer,
      url: `tel:${brandingData.value.telefoonnummer}`
    });
  }

  if (brandingData.value?.emailadres) {
    items.push({
      icon: ['far', 'envelope'],
      label: brandingData.value.emailadres,
      url: `mailto:${brandingData.value.emailadres}`
    });
  }

  if (brandingData.value?.website) {
    items.push({
      icon: ['far', 'globe-pointer'],
      label: 'Website',
      target: '_blank',
      url: `${brandingData.value.website.startsWith('http') ? '' : 'https://'}${brandingData.value.website}`
    });
  }

  return items;
});

function toggleMenu(event: Event) {
  menu.value?.toggle(event);
}
</script>

<template>
  <div
    v-if="brandingData?.emailadres || brandingData?.telefoonnummer || brandingData?.website"
    class="tw-relative tw-flex">
    <button
      class="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-border-none tw-bg-transparent tw-px-3 tw-text-sm tw-text-inherit tw-outline-none lg:tw-px-6"
      type="button"
      @click="toggleMenu">
      <FontAwesomeIcon :icon="['far', 'users']" />

      <span class="max-sm:tw-hidden">Contact</span>
    </button>

    <VMenu append-to="self" class="tw-left-auto tw-right-0 tw-top-full" :model popup ref="menu" />
  </div>
</template>

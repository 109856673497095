// theme specific styles can be found in VMenu.styles.ts
export const menu = {
  root: {
    class: ['tw-min-w-[12rem]', 'tw-w-fit']
  },
  list: {
    class: ['tw-list-none', 'tw-m-0', 'tw-py-1', 'tw-outline-none']
  },
  item: {
    class: ['tw-relative', 'tw-px-2', 'tw-py-1', 'tw-text-sm']
  },
  itemContent: {
    class: ['tw-rounded', 'tw-transition-shadow', 'tw-duration-200']
  },
  itemLink: {
    class: [
      'tw-flex',
      'tw-items-center',
      'tw-p-2',
      'tw-no-underline',
      'tw-overflow-hidden',
      'tw-cursor-pointer',
      'tw-select-none',
      "after:tw-content-['']",
      'after:tw-absolute',
      'after:tw-inset-0'
    ]
  },
  itemLabel: ({ context }: Record<string, any>) => ({
    class: [
      'tw-flex tw-items-center tw-gap-2 tw-text-sm',
      {
        '3xl:tw-grow 3xl:after:tw-ml-auto after:tw-content-[attr(data-badge)] after:tw-px-1.5 after:tw-py-1 after:tw-rounded-md after:tw-text-2xs after:tw-leading-tight after:tw-font-bold after:tw-whitespace-nowrap after:tw-bg-white/20':
          context.item.badge
      }
    ],
    'data-badge': context.item.badge
  }),
  submenuLabel: {
    class: [
      'tw-font-bold',
      'tw-m-0',
      'not-first:tw-mt-6',
      'tw-mb-1',
      'tw-px-4',
      'tw-rounded-tl-none',
      'tw-rounded-tr-none',
      'tw-text-2xs',
      'tw-uppercase'
    ]
  },
  transition: {
    enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
    enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
    leaveToClass: 'tw-opacity-0'
  }
};

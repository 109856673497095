<script lang="ts" setup>
import Popover from 'primevue/popover';
import { computed, ref } from 'vue';
import { VPopoverTheme, getPassthroughStyles } from './VPopover.styles';

const props = withDefaults(defineProps<{ theme: VPopoverTheme }>(), {
  theme: 'primary'
});

const popover = ref();
const passthroughStyles = computed(() => getPassthroughStyles(props.theme));

defineExpose({
  hide: (event: Event) => popover.value.hide(event),
  show: (event: Event) => popover.value.show(event),
  toggle: (event: Event) => popover.value.toggle(event)
});
</script>

<template>
  <Popover :pt="passthroughStyles" :pt-options="{ mergeSections: true, mergeProps: true }" ref="popover">
    <slot></slot>
  </Popover>
</template>

<script lang="ts" setup>
import { useProfile } from '@auth';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { MenuItemCommandEvent } from 'primevue/menuitem';
import { VAvatar, VMenu } from '@shared/component';
import { navigateToUrlWithPostRequest } from '@tools-lib';
import { ref } from 'vue';
import { tryMenuNavigation } from '../../urlHelper';

const props = withDefaults(
  defineProps<{
    alwaysSmall?: boolean;
    iconStyle?: string;
  }>(),
  { iconStyle: 'far' }
);

const menu = ref<InstanceType<typeof VMenu>>();

const user = useProfile();

function toggleMenu(event: Event) {
  menu.value?.toggle(event);
}
</script>

<template>
  <div class="tw-relative tw-flex">
    <button
      class="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-border-none tw-bg-transparent tw-px-4 tw-text-sm tw-text-inherit tw-outline-none"
      :class="{
        'lg:tw-px-6': !props.alwaysSmall
      }"
      type="button"
      @click="toggleMenu">
      <FontAwesomeIcon :icon="[props.iconStyle, 'cog']" />

      <span
        class="tw-hidden"
        :class="{
          'xl:tw-display-unset': !props.alwaysSmall
        }">
        Account
      </span>
    </button>

    <VMenu
      append-to="self"
      class="tw-left-auto tw-right-0 tw-top-full"
      :model="[
        {
          command: ({ originalEvent }: MenuItemCommandEvent) =>
            tryMenuNavigation(originalEvent, '/identity/user/account?returnUrl=%2Fcontent%2F'),
          icon: ['far', 'user'],
          label: 'Account',
          url: '/identity/user/account?returnUrl=%2Fcontent%2F'
        },
        {
          command: ({ originalEvent }: MenuItemCommandEvent) =>
            tryMenuNavigation(originalEvent, '/content/beheer/tussenpersoon'),
          icon: ['far', 'cog'],
          label: 'Instellingen',
          url: '/content/beheer/tussenpersoon'
        },
        {
          command: ({ originalEvent }: MenuItemCommandEvent) => {
            originalEvent.preventDefault();
            navigateToUrlWithPostRequest('/Identity/Authentication/Logout');
          },
          icon: ['far', 'right-from-bracket'],
          label: 'Uitloggen',
          url: '/Identity/Authentication/LogOut'
        }
      ]"
      popup
      ref="menu">
      <template #start>
        <div
          v-if="user"
          class="tw-grid tw-grid-cols-[auto_1fr] tw-grid-rows-2 tw-gap-x-3 tw-border-0 tw-border-b tw-border-solid tw-border-grey-200 tw-p-4">
          <VAvatar class="tw-row-span-2 tw-self-center" :name="user.fullName" />

          <span class="tw-flex tw-items-end tw-text-sm tw-text-primary-900">
            {{ user.fullName }}
          </span>

          <span class="tw-text-xs tw-text-grey-400">
            {{ user.tussenPersoon }}
          </span>
        </div>
      </template>
    </VMenu>
  </div>
</template>

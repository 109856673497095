<script lang="ts" setup>
import { FontAwesomeIcon } from '@shared/plugin';
import { debouncedWatch } from '@vueuse/core';
import { nextTick, ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    clear?: boolean;
    onFocus?: boolean;
  }>(),
  {
    placeholder: 'Zoeken...'
  }
);

const emit = defineEmits<{ input: [string]; onTab: [] }>();

const model = defineModel<string>({ default: '' });

const val = ref<string>('');
const root = ref();

watch(
  () => props.onFocus,
  () => {
    nextTick(() => root.value?.focus());
  },
  { immediate: true }
);

debouncedWatch(
  () => val.value,
  () => {
    model.value = val.value;
    emit('input', val.value);
  },
  { debounce: 300 }
);

watch(
  () => props.clear,
  () => clearSearch()
);

function clearSearch() {
  val.value = '';
  model.value = val.value;
  emit('input', val.value);
}

function onKeyDown(event: KeyboardEvent) {
  if (event.code === 'Tab') emit('onTab');
}

watch(model, newValue => (val.value = newValue), {
  immediate: true
});

const searchLabelId = uuidv4();
</script>

<template>
  <label
    class="tw-flex tw-cursor-text tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-[#DADADA] tw-bg-white tw-px-4 tw-py-2"
    :for="searchLabelId">
    <span class="tw-flex tw-w-4 tw-items-center tw-justify-center tw-text-primary-900">
      <FontAwesomeIcon icon="fa-regular fa-search" class="tw-w-3.5" />
    </span>
    <input
      :id="searchLabelId"
      class="tw-grow tw-border-none tw-bg-inherit tw-py-px placeholder:tw-text-[#A3A3A4] focus-visible:tw-outline-none"
      ref="root"
      type="search"
      autocomplete="on"
      :placeholder="placeholder"
      v-model="val"
      data-testid="v-search"
      @keydown="onKeyDown" />
    <span
      v-if="val"
      @click="clearSearch"
      class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-text-grey"
      data-testid="v-search-clear">
      <FontAwesomeIcon icon="fa-regular fa-times" class="tw-font-bold" />
    </span>
  </label>
</template>

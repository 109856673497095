import { Ref, watch } from 'vue';
import { handleError } from './helpers';
import { ErrorHandlerOptions } from './types';

/*
 * Hiermee kan je een error afhandelen en een notificatie tonen.
 * Standaard wordt er een notificatie getoond met de message van de error.
 * Je kan ook een custom message meegeven. Voor alle errors via onError
 * Als je een speciefieke error een andere error of afhandeling wil geven kan dat via de overige handlers
 * default message kan je ook aanpassen deze wordt gebruikt als er in de title geen error gevonden wordt.
 */
export function useErrorHandler(errorRef: Ref<Error> | Ref<null>, options: ErrorHandlerOptions = {}) {
  watch(errorRef, error => handleError(error, options));
}

import plugin from 'tailwindcss/plugin';

export default plugin(({ addVariant }) => {
  addVariant('em', ({ container }) => {
    container.walkRules(rule => {
      rule.selector = `.em\\:${rule.selector.slice(1)}`;
      rule.walkDecls(decl => {
        decl.value = decl.value.replace('rem', 'em');
      });
    });
  });
});

<script lang="ts" setup>
import { useFeatureToggle } from '@environment-lib';
import { VToolTip } from '@shared/component';
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useEventListener } from '@vueuse/core';
import OmniSearchBar from '../OmniSearch/OmniSearchBar.vue';

const omnisearch = ref<InstanceType<typeof OmniSearchBar>>();

const omnisearchToggle = useFeatureToggle('OmnisearchToggle');

useEventListener(window, 'message', async event => {
  const { type } = event.data;
  if (type !== 'search-shortcut') return;
  if (!omnisearchToggle.value) return;
  omnisearch.value?.open();
});
</script>

<template>
  <template v-if="omnisearchToggle">
    <a
      class="tw-flex tw-cursor-pointer tw-items-center tw-px-6 tw-text-primary-900 tw-no-underline"
      @click="omnisearch?.open()">
      <VToolTip label="Ctrl + Spatie">
        <FontAwesomeIcon icon="far fa-search" />
      </VToolTip>
    </a>
    <OmniSearchBar ref="omnisearch" />
  </template>
</template>

import PrimeVue from 'primevue/config';
import PrimeLocale from 'primelocale/nl.json';
import { App } from 'vue';
import { FontAwesomeIcon, Oruga, PrimeVuePt } from '@shared/plugin';
import Maska from 'maska';
import { createPinia } from 'pinia';

import applicationInsights, { ApplicationInsightsOptions } from '@application-insights';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import { env } from '@configuration-lib';
import i18next from 'i18next';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/nl/zod.json';
import { z } from 'zod';
import { logger } from '@tools-lib';

const appDefaults = {
  install(app: App, aiOptions: ApplicationInsightsOptions) {
    const vueQueryPluginOptions: VueQueryPluginOptions = {
      enableDevtoolsV6Plugin: true,
      queryClientConfig: {
        defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
      }
    };

    app
      .use(Oruga)
      .use(PrimeVue, {
        unstyled: true,
        pt: PrimeVuePt,
        locale: PrimeLocale.nl
      })
      .use(Maska)
      .use(createPinia())
      .use(VueQueryPlugin, vueQueryPluginOptions)
      .component('font-awesome-icon', FontAwesomeIcon);

    if (!env.VUE_APP_SKIP_INSIGHTS) {
      app.use(applicationInsights, aiOptions);
    }
  }
};

const invalidTypeMelding = 'Dit veld is verplicht';
translation.errors.invalid_type_received_undefined = invalidTypeMelding;
translation.errors.invalid_type = invalidTypeMelding;

i18next.init({
  lng: 'nl',
  resources: {
    nl: { zod: translation }
  }
});

z.setErrorMap(zodI18nMap);

logger.debug('Using following base url:', env.BASE_URL);
export default appDefaults;

import { logger } from '@tools-lib';

const environmentName =
  typeof config === 'undefined' || config.environment.name === '__environment__'
    ? 'local'
    : config.environment.name.toLowerCase();

const showBanner =
  typeof config !== 'undefined'
    ? config.environment.showBanner.toLowerCase() === 'true' || config.environment.showBanner === '__showBanner__'
    : false;

logger.debug('Using following environment:', environmentName, showBanner);

export { environmentName, showBanner };

import { Config, Notification, Table } from '@oruga-ui/oruga-next';
import { App, ref } from 'vue';
import { logger } from '@tools-lib';
import { useNotificationStore } from './notificationStore';
import { Colors, NotificationParams } from './types';

export interface TableDragEvent {
  event: DragEvent;
  row: any;
  index: number;
}

export interface Notification {
  open: (notificationParams: NotificationParams) => void;
  success: (message: string, indefinite?: boolean) => void;
  error: (message: string) => void;
}

const _notification: Notification = {
  open() {
    logger.warn('Notification called before oruga was initialized', new Error().stack);
  },
  success(message: string, indefinite = false) {
    this.open({ variant: Colors.Success, message, indefinite, closable: indefinite });
  },
  error(message: string) {
    this.open({ closable: true, indefinite: true, variant: Colors.Danger, message });
  }
};

const dragItemStart = ref();

function handleDragStart(this: any, event: DragEvent, row: any, index: number) {
  if (!this.draggable) return;
  dragItemStart.value = event.currentTarget;
  dragItemStart.value.classList.add('is-disabled');

  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = 'move';
    const img: HTMLElement = document.getElementById('draggable-image') as HTMLElement;
    event.dataTransfer.setDragImage(img, 10, 10);
    this.$emit('dragstart', { event, row, index });
  }
}

function handleDragOver(this: any, event: DragEvent, row: any, index: number) {
  if (!this.draggable) return;
  event.preventDefault();
  (event.currentTarget as HTMLElement).classList.add('dragging');
  this.$emit('dragover', { event, row, index });
}

function handleDragLeave(this: any, event: DragEvent, row: any, index: number) {
  if (!this.draggable) return;
  event.preventDefault();
  (event.currentTarget as HTMLElement).classList.remove('dragging');
  this.$emit('dragleave', { event, row, index });
}

function handleDrop(this: any, event: DragEvent, row: any, index: number) {
  if (!this.draggable) return;
  event.preventDefault();
  (event.currentTarget as HTMLElement).classList.remove('dragging');

  this.$emit('drop', { event, row, index });
}

function handleDragEnd(this: any, event: DragEvent, row: any, index: number) {
  if (!this.draggable) return;
  dragItemStart.value.classList.remove('is-disabled');
  dragItemStart.value = null;
  this.$emit('dragend', { event, row, index });
}

async function setStickyHeader(this: any, element: HTMLElement) {
  const wrapper = element.querySelector('.o-table__wrapper') as HTMLElement;
  wrapper.classList.remove('o-table__wrapper--sticky-header');

  let fixedHeader = document.getElementById('table-fixed-header');
  if (fixedHeader) fixedHeader.remove();
  if (document.getElementById('table-anchor')) document.getElementById('table-anchor')?.remove();
  const anchor = document.createElement('span');
  anchor.id = 'table-anchor';
  element.parentNode?.insertBefore(anchor, element);

  if (this.isMobile) return;

  const header = await document.querySelector('[data-header] .nav');

  const options = {
    root: null,
    rootMargin: `${(header as HTMLElement).offsetHeight * -1}px 0px 0px 0px`,
    threshold: 1.0
  };

  async function onIntersect(entries: any) {
    const table = await element.querySelector('table');
    const tableWidth = element?.getBoundingClientRect().width;
    const { tHead } = table as HTMLTableElement;

    const headerClone = tHead?.cloneNode(true) as HTMLTableElement;
    headerClone.style.width = `${tableWidth}px`;
    headerClone.id = 'table-fixed-header';
    if (tHead) {
      for (let c = 0; c < tHead.rows[0].cells.length; c++) {
        const cell = tHead.rows[0].cells[c];
        headerClone.rows[0].cells[c].style.minWidth = `${cell.offsetWidth}px`;
      }
    }

    entries.forEach((entry: any) => {
      fixedHeader = document.getElementById('table-fixed-header');
      if (fixedHeader) fixedHeader.remove();
      if (!entry.intersectionRatio) {
        table?.insertBefore(headerClone, tHead);
        headerClone.scrollTo({ left: wrapper.scrollLeft });
      }
    });

    wrapper.onscroll = () => {
      headerClone.scrollTo({ left: wrapper.scrollLeft });
    };
  }

  const observer = new IntersectionObserver(onIntersect, options);
  observer.observe(anchor);
}

function onMountedTable(this: any) {
  this.$nextTick(() => {
    if (this.stickyHeader) this.setStickyHeader(this.$el);
    this.checkSort();

    if (this.draggable) {
      const div = document.createElement('div');
      div.id = 'draggable-image';
      document.body.append(div);
    }
  });
}

function onUnmountedTable(this: any) {
  const img = document.getElementById('draggable-image');
  img?.remove();
}

function onUpdatedTable(this: any) {
  this.$el.classList.remove('o-table__wrapper--sticky-header');
}

export const Oruga = {
  install: (app: App) => {
    app
      .use(Table)
      .use(Notification)
      .use(Config, {
        iconPack: 'fa-solid',
        iconComponent: 'font-awesome-icon',
        notification: {
          position: 'top',
          duration: 3000
        }
      });
    const { components } = (app as any)._context;

    // oruga table extensions
    const { OTable } = components;
    OTable.methods.handleDragStart = handleDragStart;
    OTable.methods.handleDragOver = handleDragOver;
    OTable.methods.handleDragLeave = handleDragLeave;
    OTable.methods.handleDrop = handleDrop;
    OTable.methods.handleDragEnd = handleDragEnd;
    OTable.methods.setStickyHeader = setStickyHeader;
    OTable.mounted = onMountedTable;
    OTable.unmounted = onUnmountedTable;
    OTable.updated = onUpdatedTable;

    _notification.open = (notificationParams: NotificationParams) => {
      try {
        const store = useNotificationStore();
        store.notifications.push({ ...notificationParams, id: crypto.randomUUID(), timestamp: new Date(Date.now()) });
      } catch (e) {
        logger.error('Error saving notification to localStorage', e);
      }
      app.config.globalProperties.$oruga.notification.open(notificationParams);
    };
  }
};

export function useNotification() {
  return _notification;
}
